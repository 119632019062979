//excluir este helpers após inserir populate

export const mapEquipmentData = (equipments, locations, equipmentCategories) => {
  return equipments.map((equipment) => {
    const location =
      locations.find((loc) => loc._id === equipment.location)?.name || 'Desconhecido';
    const category =
      equipmentCategories.find((cat) => cat.id === equipment.asset_category)?.label ||
      'Desconhecido';
    const manufacturer = 'Desconhecido';

    return {
      ...equipment,
      location,
      asset_category: category,
      manufacturer,
    };
  });
};
