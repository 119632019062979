import React from 'react';

import costCenters from '../../../../../images/costCenters.svg';

const CostCentersIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={costCenters} alt={altText} style={{ width, height }} />;
};

export default CostCentersIconComponent;
