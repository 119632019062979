import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import { removeNullParams } from './misc.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getEquipments = async (
  instance,
  locationId = null,
  categoryId = null,
  page = null,
  limit = null,
  sortField = null,
  sortOrder = null,
  searchTerm = null,
  fullData = false,
) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const sortParam = sortField ? `${sortOrder === 'descend' ? '-' : ''}${sortField}` : null;

    const { data } = await axios.get(`${apiUrl}/asset`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: removeNullParams({
        page: page,
        limit: limit,
        location: locationId,
        asset_category: categoryId,
        sort: sortParam,
        ...(searchTerm
          ? {
              'name[regex]': searchTerm,
              'name[options]': 'i',
            }
          : {}),
      }),
    });
    console.log('Data fetched from API:', data);
    if (fullData) {
      return data || {};
    }
    return data.items || [];
  } catch (e) {
    throw e;
  }
};

export const getEquipment = async (instance, equipmentId) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/asset/${equipmentId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
};
