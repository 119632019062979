import { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import useVirtualEngineerState from '../../../../VirtualEnginner/atoms/variables';

import useTemplateGlobatState from '../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';

import { Upload } from 'antd';
import { deleteAssetFile, saveAssetFile } from '../../../helpers/files';

import useGlobalState from '../../../../Template/atoms/variables';
import { useEffect } from 'react';

const { Dragger } = Upload;

export default function AddFilesModal({ notificationApi }) {
  const { setModalOpen, setModalContent, setModalConfirm, setModalDisabled } =
    useTemplateGlobatState();

  const [uploadedFiles, setUploadedFiles] = useState();
  const draggerProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      if (info.fileList.length == 0) setModalDisabled(true);
    },
    onDrop(e) {},
  };

  const { currentEquipment } = useVirtualEngineerState();

  const { setPendingApiCalls } = useGlobalState();

  const { instance } = useMsal();

  return (
    <div
      className='noEquipmentFilesAdd'
      onClick={() => {
        setModalDisabled(true);
        setModalConfirm(() => async () => {
          setModalOpen(false);
        });
        setModalContent(
          <div className='equipmentFiles'>
            <Dragger
              onRemove={async (file) => {
                setUploadedFiles((current) => current.filter((f) => f.name !== file.name));
              }}
              customRequest={async ({ file, onSuccess, onError }) => {
                setUploadedFiles((current) => [...current, file]);
                onSuccess(null, file);
                setModalDisabled(false);
                // try {
                //   data = await saveAssetFile(instance, currentEquipment?._id, file);
                //   onSuccess(data, file);
                //   setModalDisabled(false);
                // } catch (err) {
                //   onError(data, file);
                //   // API fail notification
                //   notificationApi.error({
                //     message: 'Falha ao enviar documentos',
                //     description: err?.response?.data?.message
                //       ? `${err?.response?.data?.message} (${err?.response?.status})`
                //       : err?.message,
                //     duration: 5,
                //   });
                // }
              }}
              accept='accept="application/pdf'
              {...draggerProps}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>Clique ou arraste para fazer upload de um arquivo</p>
              <p className='ant-upload-hint'>Apenas arquivos .pdf</p>
              <p className='ant-upload-hint'>Suporte para upload único ou em massa.</p>
            </Dragger>
          </div>,
        );
      }}
    >
      <div className='text'>Adicionar documentos</div>
    </div>
  );
}
