import React, { useEffect, useState } from 'react';
import { Button, notification, Select } from 'antd';
import useVirtualEngineerState from '../../../../atoms/variables';
import useGlobalState from '../../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { getEquipments } from '../../../../helpers/equipment';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { startConversation } from '../../../../helpers/conversationHelper';

const NewChatModal = () => {
  const { t } = useTranslation();

  const {
    locations,
    currentLocationId,
    currentEquipment,
    equipmentCategories,
    currentCategory,
    equipments,
    currentEquipmentId,
    setCurrentLocationId,
    setCurrentCategory,
    setEquipments,
    setCurrentEquipmentId,
    setConversations,
    setCurrentEquipment,
    setCurrentThreadId,
  } = useVirtualEngineerState();

  const {
    setModalOpen,
    setModalContent,
    setModalDisabled,
    setModalConfirm,
    setModalTitle,
    setPendingApiCalls,
  } = useGlobalState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();

  const [selectedLocation, setSelectedLocation] = useState(currentLocationId);
  const [selectedCategory, setSelectedCategory] = useState(currentCategory?.id);
  const [selectedEquipment, setSelectedEquipment] = useState(currentEquipmentId);
  const locationsWithoutParents = locations.filter(
    (location) => !location.parent && location.ancestors.length === 0,
  );

  const navigate = useNavigate();

  const content = [
    {
      key: `Local-${selectedLocation}`,
      label: t('location'),
      node: (
        <Select
          size='large'
          showSearch
          placeholder='Selecione um local'
          optionFilterProp='label'
          value={[selectedLocation]}
          onChange={(locationId) => {
            setSelectedLocation(locationId);
          }}
          options={locationsWithoutParents?.map((location) => ({
            value: location._id,
            label: location.name,
          }))}
        />
      ),
    },
    {
      key: `Categoria-${selectedCategory}`,
      label: t('category'),
      node: (
        <Select
          disabled={!selectedLocation}
          size='large'
          showSearch
          placeholder='Selecione uma categoria'
          optionFilterProp='label'
          value={selectedCategory}
          onChange={(categoryId) => {
            setSelectedCategory(categoryId);
          }}
          options={equipmentCategories?.map((category) => ({
            value: category.id,
            label: category.label,
          }))}
        />
      ),
    },
    {
      key: `Equipamento-${selectedEquipment}`,
      label: t('equipment'),
      node: (
        <Select
          disabled={!selectedLocation || !selectedCategory}
          size='large'
          showSearch
          placeholder='Selecione um equipamento'
          value={
            equipments.find((equipment) => equipment._id === selectedEquipment)
              ? selectedEquipment
              : null
          }
          optionFilterProp='label'
          onChange={(e) => {
            setSelectedEquipment(e);
          }}
          options={equipments?.map((equipment) => ({
            value: equipment._id,
            label: equipment.name,
          }))}
        />
      ),
    },
  ];

  useEffect(() => {
    setModalContent(content);
    setModalDisabled(!selectedEquipment);
    setModalConfirm(() => handleSubmit);
  }, [selectedLocation, selectedEquipment, selectedCategory, locations, equipments]);

  useEffect(() => {
    if (selectedLocation && selectedCategory) triggerApiCall(selectedLocation, selectedCategory);
  }, [selectedCategory, selectedLocation]);

  useEffect(() => {
    setSelectedLocation(currentLocationId);
    setSelectedCategory(currentCategory?.id);
    setSelectedEquipment(currentEquipmentId);
    setModalContent(content);
  }, [currentLocationId, currentCategory, currentEquipmentId]);

  function handleSubmit() {
    if (selectedEquipment) {
      setModalOpen(false);
      setCurrentCategory(equipmentCategories.find((category) => category.id === selectedCategory));
      setCurrentEquipmentId(selectedEquipment);
      setCurrentLocationId(selectedLocation);
      startConversation(
        selectedEquipment,
        instance,
        notificationApi,
        navigate,
        setPendingApiCalls,
        setConversations,
        setCurrentCategory,
        setCurrentEquipment,
        setCurrentThreadId,
      );
    } else {
      console.error('No equipment ID is set.');
    }
  }

  const triggerApiCall = async (locationId, categoryId) => {
    try {
      setPendingApiCalls((current) => current.concat(['getEquipments']));
      setSelectedEquipment(null);
      const response = await getEquipments(instance, locationId, categoryId);
      setEquipments(response?.length > 0 ? response : []);

      if (response.length == 0) {
        notificationApi.info({
          message: t('empty_database'),
          description: t('missing_items_description'),
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getEquipments'));
    }
  };

  return (
    <>
      {contextHolder}
      <Button
        type='primary'
        icon='+'
        size='large'
        onClick={() => {
          setModalContent(content);
          setModalTitle(t('select_equipment_modal'));
          setModalDisabled(!selectedEquipment || !selectedLocation || !selectedCategory);
          setModalOpen(true);
        }}
      >
        {t('new_chat')}
      </Button>
    </>
  );
};

export default NewChatModal;
