import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const chosenPositions = atom({
  key: 'chosenPositions',
  default: null,
});

export const defaultNodes = atom({
  key: 'defaultNodes',
  default: [
    {
      hierarchy: 0,
      type: 'plant',
      label: 'Planta',
      kind: 'position',
    },
    {
      hierarchy: 1,
      type: 'area',
      label: 'Area',
      kind: 'position',
    },
    {
      hierarchy: 2,
      type: 'sub-area',
      label: 'Sub-Area',
      kind: 'position',
    },
    {
      hierarchy: 3,
      type: 'line',
      label: 'Linha',
      kind: 'position',
    },
    {
      hierarchy: 0,
      type: 'machine',
      label: 'Máquina',
      kind: 'asset',
    },
    {
      hierarchy: 1,
      type: 'assembly',
      label: 'Conjunto',
      kind: 'asset',
    },
    {
      hierarchy: 2,
      type: 'equipment',
      label: 'Equipamento',
      kind: 'asset',
    },
    {
      hierarchy: 3,
      type: 'component',
      label: 'Componente',
      kind: 'asset',
    },
  ],
});

export default function useSettingsState() {
  return {
    chosenPositions: useRecoilValue(chosenPositions),
    setChosenPositions: useSetRecoilState(chosenPositions),
    defaultNodes: useRecoilValue(defaultNodes),
    setDefaultNodes: useSetRecoilState(defaultNodes),
  };
}
