import assetTreeNodeArrowClosed from '../../images/assetTreeNodeArrowClosed.svg';
import assetTreeNodeArrowOpened from '../../images/assetTreeNodeArrowOpened.svg';

import plant from '../../images/plant.svg';
import plantSelected from '../../images/plantSelected.svg';
import area from '../../images/area.svg';
import areaSelected from '../../images/areaSelected.svg';
import subArea from '../../images/subArea.svg';
import subAreaSelected from '../../images/subAreaSelected.svg';
import line from '../../images/line.svg';
import lineSelected from '../../images/lineSelected.svg';
import machine from '../../images/machine.svg';
import machineSelected from '../../images/machineSelected.svg';
import assembly from '../../images/assembly.svg';
import assemblySelected from '../../images/assemblySelected.svg';
import equipment from '../../images/equipment.svg';
import equipmentSelected from '../../images/equipmentSelected.svg';
import component from '../../images/component.svg';
import componentSelected from '../../images/componentSelected.svg';

import './style.css';

const AssetTree = ({
  assetTree,
  onNodeClick,
  isPlant,
  creating,
  possibleChild,
  disabled,
  chosenLevel,
  openedLevels,
}) => {
  const icons = [
    {
      label: 'Planta',
      type: 'plant',
      icon: <img src={plant} />,
      iconSelected: <img src={plantSelected} />,
    },
    {
      label: 'Area',
      type: 'area',
      icon: <img src={area} />,
      iconSelected: <img src={areaSelected} />,
    },
    {
      label: 'Sub-Area',
      type: 'sub-area',
      icon: <img src={subArea} />,
      iconSelected: <img src={subAreaSelected} />,
    },
    {
      label: 'Linha',
      type: 'line',
      icon: <img src={line} />,
      iconSelected: <img src={lineSelected} />,
    },
    {
      label: 'Máquina',
      type: 'machine',
      icon: <img src={machine} />,
      iconSelected: <img src={machineSelected} />,
    },
    {
      label: 'Conjunto',
      type: 'assembly',
      icon: <img src={assembly} />,
      iconSelected: <img src={assemblySelected} />,
    },
    {
      label: 'Equipamento',
      type: 'equipment',
      icon: <img src={equipment} />,
      iconSelected: <img src={equipmentSelected} />,
    },
    {
      label: 'Componente',
      type: 'component',
      icon: <img src={component} />,
      iconSelected: <img src={componentSelected} />,
    },
  ];

  return (
    <div className='assetTreeNode'>
      <div
        className={`assetTreeNodeHeader ${(creating && chosenLevel?.id !== assetTree?.id) || disabled ? 'disabled' : ''} ${isPlant ? 'plant' : ''}`}
        onClick={() => {
          if (!disabled) onNodeClick(assetTree);
        }}
      >
        <div
          className='assetTreeNodeArrow'
          style={assetTree?.children?.length > 0 ? {} : { opacity: '40%' }}
        >
          <img
            src={
              openedLevels.some((opened) => opened?.id === assetTree?.id)
                ? assetTree?.children?.length > 0
                  ? assetTreeNodeArrowOpened
                  : creating && chosenLevel?.id === assetTree?.id
                    ? assetTreeNodeArrowOpened
                    : assetTreeNodeArrowClosed
                : assetTreeNodeArrowClosed
            }
          />
        </div>

        <div
          className={`assetTreeNodeHeaderContent ${chosenLevel?.id === assetTree?.id ? 'selected' : ''}`}
        >
          <div className='assetTreeNodeIcon'>
            {icons.find((icon) => icon.type == assetTree?.type)?.icon}
          </div>
          <div className='assetTreeNodeTitle'>{assetTree?.name}</div>
        </div>
      </div>
      <div
        className={`assetTreeNodeContent ${openedLevels?.some((opened) => opened?.id === assetTree?.id) ? 'opened' : 'closed'}`}
      >
        {(assetTree?.children?.length > 0 || (creating && chosenLevel?.id === assetTree?.id)) && (
          <div className={`assetTreeNodeContentLineContainer`}>
            <div className='assetTreeNodeContentLine'></div>
          </div>
        )}
        <div className='childAssetTree'>
          {creating && chosenLevel?.id === assetTree?.id && (
            <div className='assetTreeNode'>
              <div className={`assetTreeNodeHeader`}>
                <div className='assetTreeNodeArrow' style={{ opacity: '40%' }}>
                  <img src={assetTreeNodeArrowClosed} />
                </div>

                <div className={`assetTreeNodeHeaderContent`}>
                  <div className='assetTreeNodeIcon'>
                    {icons.find((icon) => icon.type == possibleChild?.type)?.icon}
                  </div>
                  <div className='assetTreeNodeTitle creating'>{`Criando ${possibleChild?.label}`}</div>
                </div>
              </div>
            </div>
          )}
          {assetTree?.children.map((child) => {
            return (
              <AssetTree
                assetTree={child}
                onNodeClick={onNodeClick}
                isPlant={false}
                creating={creating}
                possibleChild={possibleChild}
                disabled={disabled}
                chosenLevel={chosenLevel}
                openedLevels={openedLevels}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssetTree;
