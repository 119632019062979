import React from 'react';

import adm from '../../../../../images/adm.svg';
import admSelected from '../../../../../images/admSelected.svg';

const AdmIconComponent = ({ height = '14px', width = height, altText = 'icon', selected }) => {
  return <img src={selected ? admSelected : adm} alt={altText} style={{ width, height }} />;
};

export default AdmIconComponent;
