import React from 'react';
import PropTypes from 'prop-types';

const ImageIcon = ({ src, alt = 'icon', width = '24px', height = '24px', style = {} }) => {
  if (!src) {
    return null;
  }

  return <img src={src} alt={alt} style={{ width, height, display: 'inline-block', ...style }} />;
};

ImageIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};

export default ImageIcon;
