import plant from '../../images/plant.svg';
import plantSelected from '../../images/plantSelected.svg';
import area from '../../images/area.svg';
import areaSelected from '../../images/areaSelected.svg';
import subArea from '../../images/subArea.svg';
import subAreaSelected from '../../images/subAreaSelected.svg';
import line from '../../images/line.svg';
import lineSelected from '../../images/lineSelected.svg';
import machine from '../../images/machine.svg';
import machineSelected from '../../images/machineSelected.svg';
import assembly from '../../images/assembly.svg';
import assemblySelected from '../../images/assemblySelected.svg';
import equipment from '../../images/equipment.svg';
import equipmentSelected from '../../images/equipmentSelected.svg';
import component from '../../images/component.svg';
import componentSelected from '../../images/componentSelected.svg';
import { useTranslation } from 'react-i18next';

import './style.css';

const Tree = ({
  disabled,
  treeNodes,
  nodeType = 'positions',
  maxNodeWidth = '90%',
  chosenPositions,
  setChosenPosition,
  setChosenCategory,
  setSearchString,
}) => {
  const { t } = useTranslation();

  const icons = [
    {
      type: 'plant',
      icon: <img src={plant} />,
      iconSelected: <img src={plantSelected} />,
    },
    {
      type: 'area',
      icon: <img src={area} />,
      iconSelected: <img src={areaSelected} />,
    },
    {
      type: 'sub-area',
      icon: <img src={subArea} />,
      iconSelected: <img src={subAreaSelected} />,
    },
    {
      type: 'line',
      icon: <img src={line} />,
      iconSelected: <img src={lineSelected} />,
    },
    {
      type: 'machine',
      icon: <img src={machine} />,
      iconSelected: <img src={machineSelected} />,
    },
    {
      type: 'assembly',
      icon: <img src={assembly} />,
      iconSelected: <img src={assemblySelected} />,
    },
    {
      type: 'equipment',
      icon: <img src={equipment} />,
      iconSelected: <img src={equipmentSelected} />,
    },
    {
      type: 'component',
      icon: <img src={component} />,
      iconSelected: <img src={componentSelected} />,
    },
  ];

  return (
    <div className='treeContainer'>
      <div className='treeTitle'>{nodeType === 'positions' ? t('positions') : t('assets')}</div>
      <div className='tree'>
        {treeNodes.map((node, index) => {
          return (
            <>
              <div
                key={`treeNode-${node.type}-${index}`}
                className={`treeNode ${disabled ? 'disabled' : ''} ${chosenPositions?.some((position) => position?.type == node.type) ? 'selected' : ''} grow`}
                style={{ width: `calc(${maxNodeWidth} - ${index}*5%)` }}
                onClick={() => {
                  if (!disabled) {
                    setChosenPosition(node);
                    if (setChosenCategory) setChosenCategory(null);
                    if (setSearchString) setSearchString(null);
                  }
                }}
              >
                <div className='treeNodeIcon'>
                  {
                    icons.find((icon) => icon.type === node.type)?.[
                      chosenPositions?.some((position) => position?.type == node.type)
                        ? 'iconSelected'
                        : 'icon'
                    ]
                  }
                </div>
                <div className='treeNodeText'>{node.label}</div>
              </div>
              {index != treeNodes.length - 1 && <div className='treeSpacer'></div>}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Tree;
