import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Avatar } from 'antd';
const UserIcon = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  if (!account) {
    return null;
  }

  const stringToHexColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).slice(-2);
    }

    return color;
  };

  const { given_name: givenName, family_name: familyName } = account.idTokenClaims || {};
  const firstLetter = givenName?.charAt(0).toUpperCase();

  return (
    <Avatar
      style={{
        backgroundColor: stringToHexColor(`${givenName} ${familyName}`), // Apply random background color
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {firstLetter}
    </Avatar>
  );
};

export default UserIcon;
