import { useEffect, useState } from 'react';
import './style.css';

const Input = ({
  value,
  icon,
  placeholder,
  type,
  style,
  onChange,
  disabled,
  onBlur,
  maxLength,
}) => {
  const disallowedNames = [
    'Nova Categoria',
    'Nova Propriedade',
    'Nova Planta',
    'Nova Area',
    'Nova Sub-area',
    'Nova Linha',
    'Nova Máquina',
    'Novo Conjunto',
    'Novo Equipamento',
    'Novo Componente',
  ];

  const [finalValue, setFinalValue] = useState(value);

  return (
    <div className='inputContainer'>
      {icon && <div className='inputIconContainer'>{icon}</div>}
      <input
        disabled={disabled}
        value={
          disallowedNames.some(
            (name) =>
              name.toLocaleLowerCase().replace(/ /g, '') ===
              value?.toLocaleLowerCase().replace(/ /g, ''),
          )
            ? ''
            : type === 'number'
              ? finalValue
              : value
        }
        placeholder={type === 'number' ? finalValue : placeholder}
        style={{ ...style, paddingLeft: icon ? '3rem' : '' }}
        className={`customInput ${disabled ? 'disabled' : ''}`}
        onChange={(e) => {
          if (type === 'number') {
            if (finalValue.includes('.')) {
              const lastChar = e.target.value.slice(-1);
              if (lastChar === ',' || lastChar === '.') {
                return;
              }
            }
            setFinalValue(e.target.value.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.'));
          }
          onChange(
            type === 'number'
              ? { target: { value: e.target.value.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.') } }
              : e,
          );
        }}
        maxLength={maxLength}
        onBlur={onBlur ? onBlur : () => {}}
      />
    </div>
  );
};

export default Input;
