import React from 'react';

import shield from '../../../../../images/shield.svg';
import shieldSelected from '../../../../../images/shieldSelected.svg';

const ShieldIconComponent = ({ height = '14px', width = height, altText = 'icon', selected }) => {
  return <img src={selected ? shieldSelected : shield} alt={altText} style={{ width, height }} />;
};

export default ShieldIconComponent;
