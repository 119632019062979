import React from 'react';

import { Chat } from './pages/Chat/Chat';
import VirtualEngineerHome from './pages/Home';
import { Navigate } from 'react-router-dom';

export default [
  { path: '', element: <VirtualEngineerHome /> },
  { path: 'equipments', element: <Navigate to='/virtual-engineer' replace /> },
  { path: 'equipments/:equipmentId', element: <Chat /> },
  { path: 'equipments/:equipmentId/threads/:threadId', element: <Chat /> },
];
