import React, { useEffect } from 'react';
import Icon, { StarOutlined, StarTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import useVirtualEngineerState from '../../../../atoms/variables';
import { changeUserPreferences, getUserPreferences } from '../../../../helpers/userPreferences';
import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../../../../Template/atoms/variables';
import { notification } from 'antd';
import starOutline from '../../../../images/starOutline.svg';
import { useState } from 'react';

const Favorite = () => {
  const {
    userPreferences,
    favorites,
    currentEquipment,
    setUserPreferences,
    setFavorites,
    setCurrentEquipmentId,
  } = useVirtualEngineerState();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useGlobalState();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getUserPreferences']));
      setCurrentEquipmentId(null);
      const responses = await Promise.all([getUserPreferences(instance)]);
      setUserPreferences(
        responses?.[0]?.items?.[0] ?? {
          favorites: [],
        },
      );
      setFavorites(userPreferences?.favorites ?? []);
      setIsFavorite(
        favorites?.filter((a) => {
          return a.asset_id == currentEquipment._id;
        }).length > 0,
      );
      console.log(isFavorite);
      console.log(
        favorites?.filter((a) => {
          return a.asset_id == currentEquipment._id;
        }).length > 0,
      );
      console.log(favorites);

      const anyEmptyResponse = responses.some((response) => response.length === 0);

      if (anyEmptyResponse) {
        notificationApi.info({
          message: `Banco de dados vazio`,
          description: `Não foram encontrados alguns itens necessários`,
          duration: 5,
        });
      }
    } catch (err) {
      notificationApi.error({
        message: 'Falha de comunicação com o banco de dados',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getUserPreferences'));
    }
  };

  const updateUserPreferences = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['changeUserPreferences']));
      let userPreferencesUpdate = JSON.parse(JSON.stringify(userPreferences));
      let newStatus = !isFavorite;
      if (!newStatus) {
        userPreferencesUpdate.favorites = userPreferencesUpdate.favorites.filter((a) => {
          return a.asset_id != currentEquipment._id;
        });
      } else {
        userPreferencesUpdate.favorites.push({
          asset_id: currentEquipment._id,
        });
      }
      setUserPreferences(userPreferencesUpdate);
      setFavorites(userPreferencesUpdate.favorites);
      setIsFavorite(newStatus);
      await changeUserPreferences(instance, userPreferencesUpdate);
    } catch (err) {
      notificationApi.error({
        message: 'Falha de comunicação com o banco de dados',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'changeUserPreferences'));
    }
  };

  return (
    <Button
      size='large'
      icon={
        isFavorite ? <img src={starOutline} style={{ minWidth: '1.5rem' }} /> : <StarOutlined />
      }
      style={
        isFavorite
          ? {
              minWidth: '5rem',
              background: '#ff8f2d',
              margin: 'black',
            }
          : {
              minWidth: '5rem',
              background: 'white',
              color: 'black',
            }
      }
      value={isFavorite}
      onClick={async () => await updateUserPreferences()}
    ></Button>
  );
};
export default Favorite;
