import { notification } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

import { deleteAssetFile, getAssetFiles } from '../../../../helpers/files';
import AddFilesModal from './AddFilesModal';
import useTemplateGlobatState from '../../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import moment from 'moment';

const DocumentTabContent = ({ plantId, assetId }) => {
  const { setPendingApiCalls } = useTemplateGlobatState();

  const { instance } = useMsal();
  const [files, setFiles] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAssetFiles']));
      const response = await getAssetFiles(instance, assetId);
      setFiles(response);
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: 'Falha ao carregar documentos associados',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAssetFiles'));
    }
  };

  const deleteItem = async ({ file }) => {
    try {
      setPendingApiCalls((current) => current.concat(['deleteFile']));
      await deleteAssetFile(instance, assetId, file?.name);
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: 'Falha ao deletar documentos',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'deleteFile'));
      triggerApiCalls();
    }
  };

  return (
    <div className='documentTabContent'>
      {contextHolder}
      <div className='manageFilesButtonsContainer'>
        <AddFilesModal plantId={plantId} assetId={assetId} reloadFileList={triggerApiCalls} />
      </div>

      <div className='documentPseudoTable'>
        <div className='documentPseudoTableLine header'>
          <div className='pseudoTableLineCell header'>Nome</div>
          <div className='pseudoTableLineCell header'>Data de envio</div>
          <div className='pseudoTableLineCell header'>Enviado por</div>
          <div className='pseudoTableLineCell header'></div>
        </div>
        {files.map((file) => {
          return (
            <div className='documentPseudoTableLine'>
              <div className='pseudoTableLineCell'>{file?.name?.match(/(?<=\/files\/).*/g)}</div>
              <div className='pseudoTableLineCell'>
                {file?.lastModified ? moment(file.lastModified).format('DD/MM/YYYY HH:mm') : ''}
              </div>
              <div className='pseudoTableLineCell'>
                {file?.metadata?.userid ?? 'Usuário não identificado'}
              </div>
              <div className='pseudoTableLineCell dots'>
                <div
                  className='manageDots'
                  onClick={async () => {
                    await deleteItem({ file: file });
                  }}
                >
                  <DeleteOutlined style={{ fontSize: '1.2rem' }} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentTabContent;
