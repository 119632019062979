import gridPlus from '../../images/gridPlus.svg';

import './style.css';

import datacenterIcon from '../../images/datacenterIcon.svg';
import vesselIcon from '../../images/vesselIcon.svg';
import factoryIcon from '../../images/factoryIcon.svg';
import systemIcon from '../../images/systemIcon.svg';

import { useTranslation } from 'react-i18next';

const Grid = ({ items, onClickNew, onClickItem }) => {
  const { t } = useTranslation();
  const icons = [
    { label: 'datacenter', icon: <img src={datacenterIcon} /> },
    { label: 'vessel', icon: <img src={vesselIcon} /> },
    { label: 'factory', icon: <img src={factoryIcon} /> },
    { label: 'system', icon: <img src={systemIcon} /> },
  ];

  return (
    <div className='gridContainer'>
      {[
        <div
          className='gridItem growSlightly new'
          onClick={() => {
            onClickNew();
          }}
        >
          <div className='gridItemPlus'>
            <img src={gridPlus} />
          </div>
          <div className='gridItemText'>{t('create_item')}</div>
        </div>,
      ].concat(
        items?.map((item, index) => {
          return (
            <div
              key={`gridItem-${index}`}
              className='gridItem growSlightly'
              onClick={() => {
                onClickItem(item);
              }}
            >
              <div className='gridItemIcon'>
                {icons?.find((icon) => icon?.label === item?.icon)?.icon}
              </div>
              <div className='gridItemTitle'>{item.name}</div>
              <div className='gridItemDescription'>{t('info_plant')}</div>
            </div>
          );
        }),
      )}
    </div>
  );
};
export default Grid;
