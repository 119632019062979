import React, { useState } from 'react';
import { Layout } from 'antd';
import ResizableHandle from './components/ResizableSider/index';
import logo from '../../images/logo.svg';
import logoCollapsed from '../../images/logoCollapsed.svg';
import './style.css';

import SiderMenu from './components/SiderMenu';
import useTemplateState from '../../atoms/variables';

const { Sider } = Layout;

export default function SiderComponent() {
  const { companyInfo } = useTemplateState();

  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(300);

  const toggleCollapse = () => {
    if (window.screen.width >= 980) setCollapsed(!collapsed);
  };

  const handleResize = (newWidth) => {
    if (newWidth >= 200 && newWidth <= 300) {
      setWidth(newWidth);
    } else if (newWidth < 200) {
      toggleCollapse();
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sider
        id='sider'
        width={width}
        collapsible
        collapsed={collapsed}
        trigger={null}
        className={collapsed ? 'collapsed' : ''}
      >
        <div className='head' onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
          <img
            src={companyInfo ? (companyInfo?.logo?.length > 0 ? companyInfo?.logo : logo) : logo}
            className={'logo'}
            alt='Logo'
          />
        </div>
        <SiderMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className='bottom' onClick={toggleCollapse}>
          <img
            src={collapsed ? logoCollapsed : logo}
            className={collapsed ? 'logo-collapsed' : 'logo footer'}
            alt='Logo'
          />
        </div>
      </Sider>
      <ResizableHandle onResize={handleResize} />
    </div>
  );
}
