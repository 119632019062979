export const removeDuplicates = (array, key) => {
  return array.filter(
    (value, index, self) => index === self.findIndex((t) => t[key] === value[key]),
  );
};

export const scrollDown = () => {
  setTimeout(() => {
    document.getElementById('chatMessages')?.scrollTo({ top: 9999999, behavior: 'smooth' });
  }, 1500);
};

/**
 * Remove chaves com valores `null` ou `undefined` de um objeto.
 *
 * @param {Object} params - Objeto com os parâmetros de consulta.
 * @returns {Object} - Novo objeto sem as chaves com valores `null` ou `undefined`.
 */
export const removeNullParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== null && value !== undefined),
  );
};
