import React from 'react';

import departments from '../../../../../images/departments.svg';

const DepartmentsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={departments} alt={altText} style={{ width, height }} />;
};

export default DepartmentsIconComponent;
