import { Breadcrumb } from 'antd';
import SiderMenuIcon from './SiderMenuIcon';
import { Link, useLocation } from 'react-router-dom';
import useGlobalState from '../../atoms/variables';
import useVirtualEngineerState from '../../../VirtualEnginner/atoms/variables';

export const InnerSiderControll = ({ breadcrumbs }) => {
  const { siderVisible, setSiderVisible } = useGlobalState();

  return (
    <>
      <div className={`header ${siderVisible ? 'sider-visible' : ''}`}>
        {siderVisible &&
          breadcrumbs.map((item, index) => {
            return (
              <Breadcrumb key={`breadcrumb-${index}`}>
                <Link to={item.link} style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                  {item.label}
                </Link>
              </Breadcrumb>
            );
          })}
        <button
          onClick={() => {
            setSiderVisible((current) => !current);
          }}
          className={`collapse-button ${siderVisible ? 'collapsed-chats' : ''}`}
        >
          <SiderMenuIcon height='1rem' collapsed={siderVisible} />
        </button>
      </div>
    </>
  );
};
