import React from 'react';
import { Button, Result } from 'antd';
import { useRouteError, useNavigate } from 'react-router-dom';

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  // console.error(error);

  return (
    <Result
      status={error?.status || '500'}
      title='Alguma coisa deu errado.'
      subTitle={error?.status ? `${error?.status} - ${error?.statusText || error?.message}` : ''}
      extra={
        <Button onClick={() => navigate(-1)} type='primary'>
          Voltar
        </Button>
      }
      style={{ marginTop: '5%' }}
    />
  );
}
