import './style.css';

const TripleColumnTemplate = ({
  columnColors = ['#FFFFFF', 'linear-gradient(180deg, #FDFDFD 0%, #F7F7F7 100%)', '#FFFFFF'],
  firstColumnContent,
  secondColumnContent,
  thirdColumnContent,
}) => {
  return (
    <div className='tripleColumn'>
      <div className='column first' style={{ background: columnColors[0] }}>
        {firstColumnContent}
      </div>
      <div className='column second' style={{ background: columnColors[1] }}>
        {secondColumnContent}
      </div>
      <div className='column third' style={{ background: columnColors[2] }}>
        {thirdColumnContent}
      </div>
    </div>
  );
};

export default TripleColumnTemplate;
