import React from 'react';

import assetTree from '../../../../../images/assetTree.svg';

const AssetTreeIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={assetTree} alt={altText} style={{ width, height }} />;
};

export default AssetTreeIconComponent;
