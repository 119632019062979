import './style.css';

const Checkbox = ({ label = 'label', onChange, checked, style, disabled }) => {
  return (
    <div className='checkboxContainer'>
      {label?.length > 0 && <div className='checkboxLabel'>{label}:</div>}
      <input
        className='customCheckbox'
        type='checkbox'
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange({ target: { value: e.target.checked, checked: e.target.checked } });
        }}
      />
    </div>
  );
};

export default Checkbox;
