import { useEffect, useState } from 'react';
import { Button } from 'antd';
import TripleColumnTemplate from '../../../../Template/layoutComponents/TripleColumn';

import { PlusOutlined, CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import Input from '../../../../Template/layoutComponents/Input';
import Select from '../../../../Template/layoutComponents/SingleSelect';
import Forms from '../../../../Template/layoutComponents/Forms';

import search from '../../../images/search.svg';
import deleteIcon from '../../../images/deleteIcon.svg';
import openLine from '../../../images/openLine.svg';
import closeLine from '../../../images/closeLine.svg';
import addIcon from '../../../images/addIcon.svg';

import Tree from '../../../components/Tree';
import Checkbox from '../../../../Template/layoutComponents/Checkmark';
import Tooltip from '../../../../Template/layoutComponents/Tooltip';
import i18n from '../../../../_shared/translate/translate-config';
import useSettingsState from '../../../atoms/variables';
import { useNavigate, useParams } from 'react-router-dom';
import useGlobalState from '../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../../../helpers/misc';
import CustomOrderByButton from '../../../../Template/layoutComponents/OrderButton';
import { getAllCategories, newCategory, updateCategory } from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';

import './style.css';

const AssetCategories = ({}) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { chosenType } = useParams();
  const { chosenPositions } = useSettingsState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls, companyInfo } = useGlobalState();

  useEffect(() => {
    if (chosenType != 'assets' && chosenType != 'positions')
      navigate('/settings/asset-tree/manage');
    else {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllCategories']));

      const response = await getAllCategories(instance);

      setCategoriesForKind(response);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Falha ao carregar categorias existentes',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAllCategories'));
    }
  };

  const [chosenPosition, setChosenPosition] = useState(null);
  const [chosenCategory, setChosenCategory] = useState(null);

  const [searchString, setSearchString] = useState(null);
  const [asc, setAsc] = useState(true);

  const [creatingCategory, setCreatingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);

  const [categoriesForKind, setCategoriesForKind] = useState([]);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState(false);
  const { t } = useTranslation();

  const labelMapping = {
    Planta: 'plant',
    Area: 'area',
    'Sub-Area': 'sub_area',
    Linha: 'line',
    Máquina: 'machine',
    Conjunto: 'assembly',
    Equipamento: 'equipment',
    Componente: 'component',
  };

  const translateNodes = (nodes) => {
    return nodes.map((node) => {
      const translationKey = labelMapping[node.label] || node.label.toLowerCase();
      return {
        ...node,
        label: t(translationKey),
      };
    });
  };

  const defaultCategory = {
    new: true,
    label: i18n.t('new_category'),
    type: chosenPosition?.type,
    properties: [
      {
        label: t('name'),
        type: 'text',
        value: t('new_category'),
        main: true,
        required: true,
        disabled: false,
        shouldHoldPlace: true,
      },
      {
        label: t('abbreviation'),
        type: 'text',
        value: '',
        main: true,
        required: true,
        disabled: false,
        maxLength: 4,
      },
      chosenType === 'positions' && {
        label: t('icon'),
        type: 'singleSelect',
        value: '',
        options: [
          { id: 'datacenter', label: t('datacenter') },
          { id: 'vessel', label: t('vessel') },
          { id: 'factory', label: t('factory') },
          { id: 'system', label: t('system') },
        ],
        main: true,
        required: true,
        disabled: false,
      },
    ].filter((prop) => prop),
    commissioning: false,
  };

  const disableCreateSave = () => {
    if (creatingCategory) {
      return (
        categoriesForKind.some((cat) =>
          cat.properties
            .filter((prop) => prop.main == true)
            .some((prop) =>
              chosenCategory?.properties
                .filter((currentProp) => currentProp.main == true)
                .some(
                  (currentProp) =>
                    (prop.label == currentProp.label && prop.value == currentProp.value) ||
                    currentProp.value == '',
                ),
            ),
        ) ||
        chosenCategory.properties.some(
          (prop) =>
            !prop.type ||
            prop.label == '' ||
            prop.label == 'Nova Propriedade' ||
            prop.options?.some((opt) => opt?.label == ''),
        )
      );
    }
    if (editingCategory)
      return chosenCategory.properties.some(
        (prop) =>
          !prop.type ||
          prop.label == '' ||
          prop.label == 'Nova Propriedade' ||
          prop.options?.some((opt) => opt?.label == ''),
      );

    return true;
  };

  useEffect(() => {
    const alreadyExists = categoriesForKind.some((cat) =>
      cat.properties
        .filter((prop) => prop.main == true)
        .some((prop) =>
          chosenCategory?.properties
            .filter((currentProp) => currentProp.main == true)
            .some((currentProp) => {
              if (
                prop.label == currentProp.label &&
                prop.value !== '' &&
                prop.value == currentProp.value
              ) {
                setNotificationContent(currentProp.label);
                return true;
              }
            }),
        ),
    );

    setChosenCategory(defaultCategory);
    setShowNotification(alreadyExists);
  }, [chosenCategory, t, i18n]);

  return (
    <div id='assetCategories'>
      {contextHolder}
      <TripleColumnTemplate
        firstColumnContent={
          <div className='columnContainer'>
            <div className='columnHeader' style={{ minHeight: '3rem', flexBasis: 'none' }}>
              <div className='columnTitle'>
                {chosenType === 'positions' ? t('my_positions') : t('my_assets')}
              </div>
            </div>
            <Tree
              disabled={creatingCategory || editingCategory}
              treeNodes={
                translateNodes(chosenPositions.filter((node) => chosenType.includes(node.kind))) ??
                []
              }
              maxNodeWidth='90%'
              nodeType={chosenType}
              chosenPositions={[chosenPosition]}
              setChosenPosition={setChosenPosition}
              setChosenCategory={setChosenCategory}
              setSearchString={setSearchString}
            />
          </div>
        }
        secondColumnContent={
          <div className='columnContainer' style={{ padding: 0 }}>
            <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
              <div className='columnTitle'>{t('categories')}</div>
              <Button
                disabled={chosenPosition === null || creatingCategory || editingCategory}
                icon={<PlusOutlined />}
                size='large'
                style={{
                  background:
                    chosenPosition === null || creatingCategory || editingCategory
                      ? 'grey'
                      : 'black',
                  color: 'white',
                }}
                onClick={() => {
                  setEditingCategory(false);
                  setCreatingCategory(true);
                  setChosenCategory(defaultCategory);
                }}
              >
                {t('new_category')}
              </Button>
            </div>
            <div className='searchControls' style={{ padding: '1.5rem 2rem 0 2rem' }}>
              <Input
                value={searchString ?? ''}
                onChange={(event) => {
                  setSearchString(event.target.value ?? null);
                }}
                style={{ maxWidth: '80%' }}
                placeholder={t('search')}
                icon={<img src={search} />}
              />
              <CustomOrderByButton
                onClick={() => {
                  setAsc((current) => !current);
                }}
              />
            </div>
            <div className='categoryList'>
              {categoriesForKind
                .filter((category) => {
                  return category.type == chosenPosition?.type;
                })
                .filter((category) => {
                  if (searchString)
                    return category.label.toLowerCase().includes(searchString?.toLowerCase());
                  else return true;
                })
                .concat(creatingCategory ? [defaultCategory] : [])
                .sort((a, b) =>
                  a.new == true
                    ? -1
                    : asc
                      ? a.label < b.label
                        ? -1
                        : 1
                      : a.label < b.label
                        ? 1
                        : -1,
                )
                .map((category, index) => {
                  if (category.new) {
                    return (
                      <div
                        className={`categoryListItem ${chosenCategory?.id == category.id ? 'selected' : ''} creating`}
                      >
                        {category.label}
                      </div>
                    );
                  } else
                    return (
                      <>
                        {index === 0 && <div className='categoryListDivider'></div>}
                        <div
                          className={`categoryListItem ${chosenCategory?.id == category.id ? 'selected' : ''} ${creatingCategory || editingCategory ? 'disabled' : ''}`}
                          onClick={() => {
                            if (!creatingCategory && !editingCategory) {
                              setChosenCategory(category);
                              setSearchString(null);
                            }
                          }}
                        >
                          {category.label}
                        </div>
                        <div className='categoryListDivider'></div>
                      </>
                    );
                })}
            </div>
          </div>
        }
        thirdColumnContent={
          <div
            className='columnContainer'
            style={{
              padding: 0,
              justifyContent: 'space-between',
              maxHeight: 'calc(100vh - 6rem)',
              overflow: 'auto',
            }}
          >
            {chosenCategory && (
              <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
                <div className='columnTitle'>
                  {chosenCategory?.label?.length > 0 ? chosenCategory.label : 'Nova Categoria'}
                </div>
              </div>
            )}
            <div
              className='categoryPropertiesContainer'
              style={{ padding: '1.5rem 2rem 5rem 2rem' }}
            >
              <Forms
                inputs={chosenCategory?.properties.filter((prop) => prop.main)}
                onInputBlur={() => {
                  if (showNotification) {
                    notificationApi.info({
                      message: `Valores repetidos`,
                      description: `Valor do campo '${notificationContent}' já cadastrado. Use outro valor.`,
                      duration: 5,
                    });
                  }
                }}
                onInputChange={(event, inputIndex) => {
                  if (!creatingCategory) {
                    setEditingCategory(true);
                  }

                  setChosenCategory((current) => {
                    if (current) {
                      return {
                        ...current,
                        properties: current.properties.map((item, index) => {
                          if (index == inputIndex) {
                            item.value = item.type.toLowerCase().includes('select')
                              ? { id: event.target.value, label: '' }
                              : event.target.value;
                          }
                          return item;
                        }),
                        label: current.properties[0].value,
                      };
                    }
                  });
                }}
              />
            </div>
            {chosenCategory && companyInfo?.commissioning && (
              <div
                className='categoryCommissioning'
                style={chosenType == 'positions' ? { borderTop: 'none' } : {}}
              >
                {chosenType == 'assets' && (
                  <div className='categoryCommissioningTitleContainer'>
                    <div className='categoryCommissioningTitle'>Commissioning</div>
                    <Tooltip />
                  </div>
                )}
                {chosenType == 'assets' && (
                  <div className='categoryCommissioningCheck'>
                    <Checkbox
                      label={t('commissionable_category')}
                      checked={chosenCategory.commissioning}
                      onChange={(e) => {
                        setChosenCategory((current) => {
                          if (!creatingCategory) {
                            setEditingCategory(true);
                          }

                          return { ...current, commissioning: e.target.value };
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            <SecondaryCategories
              chosenCategory={chosenCategory}
              setChosenCategory={setChosenCategory}
              setEditingCategory={setEditingCategory}
              companyInfo={companyInfo}
            />

            {chosenCategory && (
              <div className='newPropertyButtonContainer'>
                <div className='newPropertyButton'>
                  <Button
                    disabled={chosenPosition === null}
                    icon={<PlusOutlined />}
                    size='large'
                    style={{
                      borderColor: '#1777FF',
                      background: chosenPosition === null ? 'grey' : 'white',
                      color: '#1777FF',
                    }}
                    onClick={() => {
                      setEditingCategory(true);
                      setChosenCategory((current) => {
                        return {
                          ...current,
                          properties: [...current.properties].concat([
                            {
                              label: 'Nova Propriedade',
                              type: null,
                              value: '',
                              main: false,
                              required: false,
                              disabled: false,
                            },
                          ]),
                        };
                      });
                    }}
                  >
                    {t('new_property')}
                  </Button>
                </div>
              </div>
            )}
            <div className='manageCategoriesButtonsContainer'>
              {(creatingCategory || editingCategory) && (
                <Button
                  id='cancel'
                  size='large'
                  icon={<CloseCircleOutlined />}
                  style={{
                    borderColor: 'black',
                    background: 'white',
                    color: 'black',
                  }}
                  onClick={() => {
                    setEditingCategory(false);
                    setCreatingCategory(false);
                    setChosenCategory(null);
                  }}
                >
                  Cancelar
                </Button>
              )}
              {chosenCategory && (
                <Button
                  id='create'
                  className={disableCreateSave() ? 'disabled' : ''}
                  size='large'
                  disabled={disableCreateSave()}
                  style={{
                    borderColor: '#1777FF',
                    background: '#1777FF',
                    color: 'white',
                  }}
                  icon={<AppstoreAddOutlined />}
                  onClick={async () => {
                    if (creatingCategory) {
                      // SEND ITEM TO BFF (CREATE)

                      try {
                        setPendingApiCalls((current) => current.concat(['newCategory']));
                        const mongoId = await newCategory(instance, chosenCategory);

                        setCategoriesForKind((current) => {
                          return current.concat([
                            {
                              ...chosenCategory,
                              properties: chosenCategory.properties.map((prop, index) => {
                                if (prop.main == true) prop.disabled = true;
                                return prop;
                              }),
                              new: false,
                              id: mongoId,
                            },
                          ]);
                        });
                      } catch (err) {
                        // API fail notification
                        notificationApi.error({
                          message: 'Falha ao criar nova categoria',
                          description: err?.response?.data?.message
                            ? `${err?.response?.data?.message} (${err?.response?.status})`
                            : err?.message,
                          duration: 5,
                        });
                      } finally {
                        setChosenCategory(null);
                        setPendingApiCalls((current) =>
                          current.filter((item) => item !== 'newCategory'),
                        );
                      }
                    } else {
                      setCategoriesForKind((current) => {
                        return current
                          .filter((cat) => cat.id != chosenCategory.id)
                          .concat([{ ...chosenCategory }]);
                      });

                      // SEND ITEM TO BFF (UPDATE)
                      try {
                        setPendingApiCalls((current) => current.concat(['updateCategory']));
                        await updateCategory(instance, chosenCategory);
                      } catch (err) {
                        // API fail notification
                        notificationApi.error({
                          message: 'Falha ao atualizar categoria existente',
                          description: err?.response?.data?.message
                            ? `${err?.response?.data?.message} (${err?.response?.status})`
                            : err?.message,
                          duration: 5,
                        });
                      } finally {
                        setPendingApiCalls((current) =>
                          current.filter((item) => item !== 'updateCategory'),
                        );
                      }
                    }
                    setCreatingCategory(false);
                    setEditingCategory(false);
                  }}
                >
                  {creatingCategory ? t('create_item_settings') : t('save_item_settings')}
                </Button>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

const SecondaryCategories = ({
  chosenCategory,
  setChosenCategory,
  setEditingCategory,
  companyInfo,
}) => {
  return (
    <div className='categorySecondaryPropertiesContainer'>
      {chosenCategory?.properties
        .filter((prop) => !prop.main)
        .map((prop, index) => {
          return (
            <div
              className='categorySecondaryPropertyContainer'
              style={!companyInfo?.commissioning ? { borderTop: '1px solid #ebecee' } : {}}
              key={`categorySecondaryPropertyContainer-${index}`}
            >
              <div
                className='categorySecondaryPropertyHeader'
                onClick={() => {
                  setChosenCategory((current) => {
                    return {
                      ...current,
                      properties: current.properties.map((item, ind) => {
                        if (ind === index) {
                          item.open = item.open ? false : true;
                        }
                        return item;
                      }),
                    };
                  });
                }}
              >
                <div className='categorySecondaryTitle'>
                  <div className='categorySecondaryTitleText'>
                    {prop?.label?.length > 0 ? prop.label : 'Nova Propriedade'}
                  </div>
                  <div
                    className='categorySecondaryTitleDelete'
                    onClick={() => {
                      setEditingCategory(true);
                      setChosenCategory((current) => {
                        return {
                          ...current,
                          properties: [
                            ...current.properties.filter((cat) => cat.main),
                            ...current.properties
                              .filter((cat) => !cat.main)
                              .filter((item, ind) => {
                                if (item.main) return true;
                                else return ind != index;
                              }),
                          ],
                        };
                      });
                    }}
                  >
                    <img src={deleteIcon} />
                  </div>
                </div>
                <div className='categorySecondaryArrow'>
                  <img src={chosenCategory?.properties?.[index]?.open ? closeLine : openLine} />
                </div>
              </div>
              <div
                className={`categorySecondaryPropertyContent ${chosenCategory?.properties?.[index]?.open ? 'open' : 'close'} `}
              >
                <FormsBody
                  setEditingCategory={setEditingCategory}
                  setChosenCategory={setChosenCategory}
                  index={index}
                  prop={prop}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const FormsBody = ({ setEditingCategory, setChosenCategory, index, prop }) => {
  const handlePropertyNameInput = (e) => {
    setEditingCategory(true);
    setChosenCategory((current) => {
      return {
        ...current,
        properties: [
          ...current.properties.filter((cat) => cat.main),
          ...current.properties
            .filter((cat) => !cat.main)
            .map((item, ind) => {
              if (ind === index) {
                return { ...item, label: e.target.value?.replace(/ /g, '') };
              } else return item;
            }),
        ],
      };
    });
  };
  const handlePropertyTypeInput = (e) => {
    setEditingCategory(true);
    setChosenCategory((current) => {
      return {
        ...current,
        properties: [
          ...current.properties.filter((cat) => cat.main),
          ...current.properties
            .filter((cat) => !cat.main)
            .map((item, ind) => {
              if (ind === index) {
                return {
                  ...item,
                  type: e.target.value,
                  options: e.target.value.includes('Select')
                    ? item?.options?.length > 0
                      ? item?.options
                      : [{ id: '', label: '' }]
                    : null,
                };
              } else return item;
            }),
        ],
      };
    });
  };

  return (
    <div className='formsBody'>
      <div className='formsInputContainer'>
        <div className='formsInputLabel'>Nome da Propriedade:</div>
        <div className='formsInput'>
          <Input
            value={prop.label}
            placeholder={prop.label}
            onChange={(e) => {
              handlePropertyNameInput(e);
            }}
          />
        </div>
      </div>
      <div className='formsInputContainer'>
        <div className='formsInputLabel'>Tipo da Propriedade:</div>
        <div className='formsInput'>
          <Select
            value={{ id: prop.type }}
            options={[
              { id: 'bool', label: 'Checkbox' },
              { id: 'text', label: 'Texto' },
              { id: 'number', label: 'Número' },
              { id: 'singleSelect', label: 'Escolha única' },
              { id: 'multiSelect', label: 'Escolha múltipla' },
            ]}
            onChange={(e) => {
              handlePropertyTypeInput(e);
            }}
          />
        </div>
      </div>
      {prop.type?.includes('Select') &&
        prop.options?.map((option, optionIndex) => {
          return (
            <div className='formsInputContainer'>
              <div
                className='formsInputLabel'
                style={{ fontWeight: 600, textAlign: 'left' }}
              >{`Opção ${optionIndex + 1}:`}</div>
              <div className='formsInput'>
                <Input
                  onChange={(e) => {
                    setEditingCategory(true);
                    setChosenCategory((current) => {
                      return {
                        ...current,
                        properties: [
                          ...current.properties.filter((cat) => cat.main),
                          ...current.properties
                            .filter((cat) => !cat.main)
                            .map((item, ind) => {
                              if (ind === index) {
                                return {
                                  ...item,
                                  options: item.options.map((optionItem, ind) => {
                                    if (ind === optionIndex) {
                                      return {
                                        id: e.target.value?.replace(/ /g, '')?.toLowerCase(),
                                        label: e.target.value?.replace(/ /g, ''),
                                      };
                                    } else return optionItem;
                                  }),
                                };
                              } else return item;
                            }),
                        ],
                      };
                    });
                  }}
                  value={option.label}
                  placeholder={'Nome da opção'}
                />
                <div
                  className='deletePropertyIcon'
                  onClick={() => {
                    setEditingCategory(true);
                    setChosenCategory((current) => {
                      return {
                        ...current,
                        properties: [
                          ...current.properties.filter((cat) => cat.main),
                          ...current.properties
                            .filter((cat) => !cat.main)
                            .map((item, ind) => {
                              if (ind === index) {
                                return {
                                  ...item,
                                  options: item.options.filter((op, ind) => ind != optionIndex),
                                };
                              } else return item;
                            }),
                        ],
                      };
                    });
                  }}
                >
                  <img src={deleteIcon} />
                </div>
              </div>
            </div>
          );
        })}

      {prop.type?.includes('Select') && (
        <div className='addPropertyOptionContainer'>
          <div
            className='addPropertyOption'
            onClick={() => {
              setEditingCategory(true);
              setChosenCategory((current) => {
                return {
                  ...current,
                  properties: [
                    ...current.properties.filter((cat) => cat.main),
                    ...current.properties
                      .filter((cat) => !cat.main)
                      .map((item, ind) => {
                        if (ind === index) {
                          return {
                            ...item,
                            options: [...item.options, { id: '', label: '' }],
                          };
                        } else return item;
                      }),
                  ],
                };
              });
            }}
          >
            <div className='addPropertyIcon'>
              <img src={addIcon} />
            </div>
            <div className='addPropertyText'>Nova Opção</div>
          </div>
        </div>
      )}
      <div className='formsInputContainer'>
        <div className='formsInput'>
          <Checkbox
            label='Campo obrigatório'
            checked={prop.required}
            onChange={(e) => {
              setEditingCategory(true);
              setChosenCategory((current) => {
                return {
                  ...current,
                  properties: [
                    ...current.properties.filter((cat) => cat.main),
                    ...current.properties
                      .filter((cat) => !cat.main)
                      .map((item, ind) => {
                        if (ind === index) {
                          return {
                            ...item,
                            required: e.target.value,
                          };
                        } else return item;
                      }),
                  ],
                };
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AssetCategories;
