import { atom, atomFamily, selector, useRecoilValue, useSetRecoilState } from 'recoil';

export const existingAssetTrees = atom({
  key: 'existingAssetTrees',
  default: [],
});
export const chosenPlant = atom({
  key: 'chosenPlant',
  default: null,
});

export default function useMaintenanceState() {
  return {
    existingAssetTrees: useRecoilValue(existingAssetTrees),
    setExistingAssetTrees: useSetRecoilState(existingAssetTrees),
    chosenPlant: useRecoilValue(chosenPlant),
    setChosenPlant: useSetRecoilState(chosenPlant),
  };
}
