import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGlobalState from '../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useMaintenanceState from '../atoms/variables';
import { getExistingAssetTrees } from '../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
export const MaintenanceOutlet = () => {
  const { instance } = useMsal();
  const { setTitle, setPendingApiCalls } = useGlobalState();
  const { setExistingAssetTrees } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  useEffect(() => {
    setTitle(null);
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getExistingAssetTrees', 'getAssets', 'getInstruments']),
      );

      const responses = await Promise.all([
        getExistingAssetTrees(instance),
        // getAssets(),
        // getInstruments(),
      ]);
      setExistingAssetTrees(responses[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getExistingAssetTrees' && item !== 'getAssets' && item !== 'getInstruments',
        ),
      );
    }
  };

  return (
    <div className='default-module-outlet'>
      {contextHolder}
      <Outlet />
    </div>
  );
};
