import './style.css';

const DoubleColumnTemplate = ({
  customColumnGrow = [1, 3],
  columnColors = ['#FFFFFF', '#F9FCFE'],
  firstColumnContent,
  secondColumnContent,
  noBorder,
}) => {
  return (
    <div className='doubleColumn'>
      <div
        className='column first'
        style={{ flexGrow: customColumnGrow[0], backgroundColor: columnColors[0] }}
      >
        {firstColumnContent}
      </div>
      <div
        className={`column second ${noBorder ? 'noBorder' : ''}`}
        style={{ flexGrow: customColumnGrow[1], backgroundColor: columnColors[1] }}
      >
        {secondColumnContent}
      </div>
    </div>
  );
};

export default DoubleColumnTemplate;
