import axios from 'axios';
import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getHierarchy(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/hierarchy/getHierarchy`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function saveHierarchy(instance, hierarchyLevels) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/hierarchy/saveHierarchy`, hierarchyLevels, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAllCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/category/getAllCategories`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/category/newCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/category/updateCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}
