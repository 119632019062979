import React, { useEffect } from 'react';
import { Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import useGlobalState from '../../../Template/atoms/variables';

import moment from 'moment';

const MessageInfoModal = ({ source }) => {
  const { setModalOpen, setModalContent, setModalDisabled, setModalConfirm, setModalTitle } =
    useGlobalState();

  const columns = [
    {
      title: 'Nome do Documento',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (_, record) =>
        record.document?.url ? (
          <a href={record.document?.url} target='_blank'>
            {record.document.name}
          </a>
        ) : (
          record.document.name
        ),
    },
    { title: 'Páginas relevantes', dataIndex: 'relevantPages', key: 'relevantPages' },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) =>
        record.document?.createdAt
          ? moment(record.document?.createdAt).format('DD/MM/YYYY HH:mm')
          : '-',
    },
  ];

  return (
    <InfoCircleOutlined
      className='info'
      onClick={() => {
        setModalContent([
          {
            key: `messageSource`,
            label: null,
            node: <Table columns={columns} dataSource={source} pagination={false} />,
          },
        ]);
        setModalTitle('Resposta baseada no(s) seguinte(s) documento(s):');
        setModalDisabled(false);
        setModalOpen(true);
        setModalConfirm(null);
      }}
    />
  );
};

export default MessageInfoModal;
