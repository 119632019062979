import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getAssetFiles = async (instance, assetId) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/asset/file/${assetId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const saveAssetFile = async (instance, assetId, file, onUploadProgress) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/asset/file/${assetId}`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteAssetFile = async (instance, assetId, fileName) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.delete(`${apiUrl}/asset/file/${assetId}`, {
      params: {
        fileName,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
};
