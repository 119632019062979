import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getUserPreferences = async (instance) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/userPreferences`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        user_id: instance.getActiveAccount().idTokenClaims?.sub
      }
    });
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const changeUserPreferences = async (instance, userPreferences) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/userPreferences`, {
      userPreferences
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return data
  } catch (e) {
    console.log(e);
    throw e;
  }
}