import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import useVirtualEngineerState from '../../../../atoms/variables';
import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import { startConversation } from '../../../../helpers/conversationHelper';
import useGlobalState from '../../../../../Template/atoms/variables';

const FavoritesTable = (equipments) => {
  const navigate = useNavigate();

  const { setConversations, setCurrentCategory, setCurrentEquipment, setCurrentThreadId } =
    useVirtualEngineerState();

  const { setPendingApiCalls } = useGlobalState();

  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();

  const startConversationEquipment = (equipment) => {
    startConversation(
      equipment._id,
      instance,
      notificationApi,
      navigate,
      setPendingApiCalls,
      setConversations,
      setCurrentCategory,
      setCurrentEquipment,
      setCurrentThreadId,
    );
  };

  return (
    <div className='favorites'>
      {equipments?.equipments?.map((equipment, index) => {
        return (
          <div
            className={index % 3 == 2 ? 'favorite' : 'favorite border'}
            onClick={() => startConversationEquipment(equipment)}
          >
            <div className='name'>
              <p>{equipment?.name?.toUpperCase()}</p>
            </div>
            <div className='serial'>
              <p>{equipment?.serial_number}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FavoritesTable;
