import React from 'react';

import workOrders from '../../../../../images/workOrders.svg';

const WorkOrdersIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={workOrders} alt={altText} style={{ width, height }} />;
};

export default WorkOrdersIconComponent;
