import order from '../../images/order.svg';
import { SearchOutlined } from '@ant-design/icons';

import './style.css';

const CustomSearchByButton = ({ onClick, style = {}, disabled }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      className={`orderByButton ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      style={{
        ...style,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.3 : 1,
      }}
    >
      <SearchOutlined />
    </div>
  );
};
export default CustomSearchByButton;
