import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { LogoutOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import UserIcon from '../../../UserIcon';
import ImageIcon from './components/Icons/IconsComponent';
import i18n from '../../../../../_shared/translate/translate-config';
import languageIcon from '../../../../images/language_icon.svg';
import brazilFlag from '../../../../images/brazil_flag.svg';
import usaFlag from '../../../../images/usa_flag.svg';
import spainFlag from '../../../../images/spain_flag.svg';
import './style.css';

const languages = [
  {
    value: 'en',
    label: 'English',
    flag: <ImageIcon src={usaFlag} height='16px' width='16px' style={{ marginRight: '8px' }} />,
  },
  {
    value: 'pt',
    label: 'Português',
    flag: <ImageIcon src={brazilFlag} height='16px' width='16px' style={{ marginRight: '8px' }} />,
  },
  {
    value: 'es',
    label: 'Español',
    flag: <ImageIcon src={spainFlag} height='16px' width='16px' style={{ marginRight: '8px' }} />,
  },
];

const UserIconComponent = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const [language, setLanguage] = useState(i18n.language || 'en');
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const signOutClickHandler = () => {
    const logoutRequest = {
      account,
      postLogoutRedirectUri: `${window.location.origin}`,
    };
    instance.logoutRedirect(logoutRequest);
  };

  if (!account) {
    return null;
  }

  const { given_name: givenName, family_name: familyName } = account.idTokenClaims || {};

  const handleLanguageChange = (key, e) => {
    e.preventDefault();
    e.stopPropagation();
    setLanguage(key);
    i18n.changeLanguage(key);
  };

  const toggleLanguageMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLanguageMenuOpen((prev) => !prev);
  };

  const menu = (
    <Menu>
      <Menu.Item key='user-info'>
        <span className='username'>
          {givenName && familyName ? `${givenName} ${familyName}` : 'Usuário'}
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='language-toggle'>
        <div
          onClick={(e) => toggleLanguageMenu(e)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <ImageIcon src={languageIcon} height='16px' width='16px' style={{ marginRight: '8px' }} />
          {i18n.t('language')}
          {isLanguageMenuOpen ? (
            <UpOutlined style={{ marginLeft: '8px' }} />
          ) : (
            <DownOutlined style={{ marginLeft: '8px' }} />
          )}
        </div>
      </Menu.Item>
      {isLanguageMenuOpen && (
        <Menu.ItemGroup>
          {languages.map(({ value, label, flag }) => (
            <Menu.Item key={value}>
              <div
                onClick={(e) => handleLanguageChange(value, e)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {flag}
                {label}
              </div>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      )}
      <Menu.Divider />
      <Menu.Item key='logout' onClick={signOutClickHandler}>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          {i18n.t('logout')}
          <LogoutOutlined style={{ marginLeft: '4rem' }} />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='user-container'>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className='user-icon-container' style={{ cursor: 'pointer' }}>
          <UserIcon />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserIconComponent;
