import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGlobalState from '../../Template/atoms/variables';

export const SettingsOutlet = () => {
  const { setTitle } = useGlobalState();

  useEffect(() => {
    setTitle(null);
  }, []);

  return (
    <div className='default-module-outlet'>
      <Outlet />
    </div>
  );
};
