import { useEffect, useState } from 'react';

import ShieldIconComponent from '../Icons/ShieldIcon';
import AdmIcon from '../Icons/AdmIcon';
import MaintenanceIconComponent from '../Icons/MaintenanceIcon';
import HelmetIcon from '../Icons/HelmetIcon';
import { useLocation, useNavigate } from 'react-router-dom';

import useSettingsState from '../../../../../Settings/atoms/variables';
import useMaintenanceState from '../../../../../Maintenance/atoms/variables';

import ArrowIconComponent from '../Icons/ArrowIcon';
import PlantsIconComponent from '../Icons/PlantsIcon';
import AllAssetsIconComponent from '../Icons/AllAssetsIcon';
import InstrumentsIconComponent from '../Icons/InstrumentsIcon';
import AssetsIconComponent from '../Icons/AssetsIcon';
import InterconnectionsIconComponent from '../Icons/InterconnectionsIcon';
import SystemsIconComponent from '../Icons/SystemsIcon';
import WorkOrdersIconComponent from '../Icons/WorkOrdersIcon';
import OrganizationsIconComponent from '../Icons/OrganizationsIcon';
import DepartmentsIconComponent from '../Icons/DepartmentsIcon';
import CostCentersIconComponent from '../Icons/CostCentersIcon';
import AssetTreeIconComponent from '../Icons/AssetTreeIcon';
import UserGroupsIconComponent from '../Icons/UserGroupsIcon';
import UsersIconComponent from '../Icons/UsersIcon';
import { useTranslation } from 'react-i18next';

import './style.css';
import useTemplateState from '../../../../atoms/variables';

const SiderMenu = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { chosenPositions } = useSettingsState();
  const { chosenPlant } = useMaintenanceState();
  const { companyInfo } = useTemplateState();
  const [forceClosed, setForceClosed] = useState([]);

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleResizeWindow = () => {
    if (window.screen.width <= 980) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  const items = [
    {
      key: '/maintenance',
      label: t('maintenance'),
      icon: (
        <MaintenanceIconComponent
          height='1.2rem'
          selected={location.pathname.includes('maintenance')}
        />
      ),
      child: [
        {
          key: '/plants',
          label: t('plants'),
          icon: <PlantsIconComponent height='1.2rem' />,
          child: [
            {
              disabled: chosenPlant ? false : true,
              key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/assets`,
              label: t('assets'),
              icon: <AssetsIconComponent height='1.2rem' />,
            },
            {
              disabled: chosenPlant ? false : true,
              key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/interconnections`,
              label: t('interconnections'),
              icon: <InterconnectionsIconComponent height='1.2rem' />,
            },
            {
              disabled: chosenPlant ? false : true,
              key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/systems`,
              label: t('systems'),
              icon: <SystemsIconComponent height='1.2rem' />,
            },
            {
              disabled: chosenPlant ? false : true,
              key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/work-orders`,
              label: t('work_orders'),
              icon: <WorkOrdersIconComponent height='1.2rem' />,
            },
          ],
        },
        {
          key: '/assets',
          label: t('all_assets'),
          icon: <AllAssetsIconComponent height='1.2rem' />,
        },
        {
          key: '/instruments',
          label: t('instruments'),
          icon: <InstrumentsIconComponent height='1.2rem' />,
        },
      ],
    },
    {
      key: '/virtual-engineer',
      label: 'Virtual Engineer',
      icon: (
        <HelmetIcon height='1.2rem' selected={location.pathname.includes('virtual-engineer')} />
      ),
    },
    {
      disabled: true,
      key: '/safety-copilot',
      label: 'Safety Copilot',
      icon: (
        <ShieldIconComponent
          height='1.2rem'
          selected={location.pathname.includes('safety-copilot')}
        />
      ),
    },
    {
      key: '/settings',
      label: t('administration'),
      icon: <AdmIcon height='1.2rem' selected={location.pathname.includes('settings')} />,
      child: [
        {
          disabled: true,
          key: '/organizations',
          label: t('organizations'),
          icon: <OrganizationsIconComponent height='1.2rem' />,
        },
        {
          disabled: true,
          key: '/departments',
          label: t('departments'),
          icon: <DepartmentsIconComponent height='1.2rem' />,
        },
        {
          disabled: true,
          key: '/cost-centers',
          label: t('cost_centers'),
          icon: <CostCentersIconComponent height='1.2rem' />,
        },
        {
          key: chosenPositions?.length > 0 ? '/asset-tree/manage' : '/asset-tree',
          label: t('asset_tree'),
          icon: <AssetTreeIconComponent height='1.2rem' />,
        },
        {
          disabled: true,
          key: '/user-groups',
          label: t('user_groups'),
          icon: <UserGroupsIconComponent height='1.2rem' />,
        },
        {
          disabled: true,
          key: '/users',
          label: t('users'),
          icon: <UsersIconComponent height='1.2rem' />,
        },
      ],
    },
  ];

  const handleMenuClick = (path) => {
    if (location.pathname === path && !forceClosed.includes(path)) {
      if (path != '/maintenance/plants') setForceClosed((current) => current.concat([path]));
    } else if (location.pathname.includes(path) && !forceClosed.includes(path)) {
      if (path != '/maintenance/plants') setForceClosed((current) => current.concat([path]));
      navigate(path);
    } else {
      setForceClosed((current) => current.filter((item) => item !== path));
      navigate(path);
    }
  };

  return (
    <div className='siderMenu'>
      {companyInfo ? (
        items
          .filter((item) => companyInfo?.modules.includes(item.key))
          .map((item) => (
            <>
              <div className='siderDivider'></div>
              <div className='menuList siderModule'>
                <div
                  className={`menuButton ${item.disabled ? 'disabled' : ''}  ${collapsed ? 'collapsed' : ''} ${collapsed ? (location.pathname.includes(item.key) ? 'selected' : '') : location.pathname == item.key ? 'selected' : ''} siderModuleContent`}
                  onClick={() => {
                    if (!item.disabled) handleMenuClick(item.key);
                  }}
                >
                  {item.icon}
                  {!collapsed && item.label}
                  {!collapsed && item.child && (
                    <ArrowIconComponent
                      open={location.pathname.includes(item.key) && !forceClosed.includes(item.key)}
                    />
                  )}
                </div>

                {item?.child?.length && (
                  <div className={`wrapper ${collapsed ? 'collapsed' : ''}`}>
                    <div className={`moduleContent ${collapsed ? 'collapsed' : ''}`}>
                      {(collapsed || location.pathname.includes(item.key)) &&
                        item?.child?.map((child) => (
                          <div className='menuList moduleMenu'>
                            <div
                              className={`menuButton ${child.disabled ? 'disabled' : ''} ${child.child ? (location.pathname == `${item.key}${child.key}` ? 'selected' : '') : location.pathname.includes(`${item.key}${child.key}`) ? 'selected' : ''} moduleMenuContent`}
                              onClick={() => {
                                if (!child.disabled) handleMenuClick(`${item.key}${child.key}`);
                              }}
                            >
                              {child.icon}
                              {child.label}
                              {child.child && (
                                <ArrowIconComponent
                                  open={
                                    location.pathname.includes(child.key) &&
                                    !forceClosed.includes(`${item.key}${child.key}`)
                                  }
                                />
                              )}
                            </div>
                            {location.pathname.includes(child.key) &&
                              !forceClosed.includes(`${item.key}${child.key}`) &&
                              child?.child?.map((grandson) => (
                                <div className='menuOption'>
                                  <div
                                    className={`menuButton ${grandson.disabled ? 'disabled' : ''} ${location.pathname.includes(`${item.key}${child.key}${grandson.key}`) ? 'selected' : ''} menuOptionContent`}
                                    onClick={() => {
                                      if (!grandson.disabled)
                                        handleMenuClick(`${item.key}${child.key}${grandson.key}`);
                                    }}
                                  >
                                    {grandson.icon}
                                    {grandson.label}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ))
      ) : (
        <>
          <div className='siderDivider'></div>
          <div className='menuList siderModule  skeleton-box'>
            <div className={`menuButton siderModuleContent`}>
              <MaintenanceIconComponent height='1.2rem' />. . .
            </div>
          </div>
          <div className='siderDivider'></div>
          <div className='menuList siderModule  skeleton-box'>
            <div className={`menuButton siderModuleContent`}>
              <HelmetIcon />. . .
            </div>
          </div>
          <div className='siderDivider'></div>
          <div className='menuList siderModule  skeleton-box'>
            <div className={`menuButton siderModuleContent`}>
              <ShieldIconComponent />. . .
            </div>
          </div>
          <div className='siderDivider'></div>
          <div className='menuList siderModule  skeleton-box'>
            <div className={`menuButton siderModuleContent`}>
              <AdmIcon />. . .
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SiderMenu;
