import React from 'react';

import instruments from '../../../../../images/instruments.svg';

const InstrumentsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={instruments} alt={altText} style={{ width, height }} />;
};

export default InstrumentsIconComponent;
