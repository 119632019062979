import order from '../../images/order.svg';

import './style.css';

const CustomOrderByButton = ({ onClick }) => {
  return (
    <div className='orderByButton' onClick={onClick}>
      <img style={{ height: '1rem' }} src={order} />
    </div>
  );
};
export default CustomOrderByButton;
