import React from 'react';
import openIcon from '../../../images/closeMenu.svg';
import closeIcon from '../../../images/openMenu.svg';

const SiderChatIcon = ({ height = '14px', width = height, altText = 'icon', collapsed }) => {
  return (
    <img
      src={collapsed ? openIcon : closeIcon}
      alt={altText}
      style={{ width, height, color: 'black' }}
    />
  );
};

export default SiderChatIcon;
