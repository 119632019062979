import { Outlet, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import useGlobalState from '../../../Template/atoms/variables';
import { useEffect } from 'react';
import { sleep } from '../../../helpers/misc';

const PlantsOutlet = () => {
  const navigate = useNavigate();

  const { setPendingApiCalls } = useGlobalState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
    } finally {
    }
  };

  return (
    <>
      <Outlet />
    </>
  );
};

export default PlantsOutlet;
