import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useGlobalState from '../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import './style.css';

export const Modal = () => {
  const { modalTitle, modalOpen, setModalOpen, modalContent, modalConfirm, modalDisabled } =
    useGlobalState();
  const { t } = useTranslation();

  return (
    <div className={`modalFullScreenWrapper ${modalOpen ? 'closed' : ''}`}>
      <div className='modalBodyContainer'>
        <CloseOutlined className='closeIcon' onClick={() => setModalOpen(false)} />
        <div className='modalTitle'>{modalTitle}</div>
        <div className='modalContent'>
          {Array.isArray(modalContent)
            ? modalContent?.map((content) => (
                <div key={content.key} className='modalField'>
                  {content.label && <div className='modalFieldLabel'>{content.label}</div>}
                  <div className='modalFieldNode'>{content.node}</div>
                </div>
              ))
            : modalContent}
        </div>
        {modalConfirm !== null && (
          <div className='modalFooter'>
            <Button type='text' onClick={() => setModalOpen(false)}>
              {t('cancel-btn')}
            </Button>
            <Button
              disabled={modalDisabled}
              style={{ background: modalDisabled ? 'grey' : 'black', color: 'white' }}
              onClick={modalConfirm}
            >
              {t('confirm-btn')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
