import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { msalConfig } from './config.js';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

export const acquireToken = async (instance, scope, account = null) => {
  try {
    const response = await instance.acquireTokenSilent({
      scopes: [scope],
      account: account || instance.getActiveAccount(),
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect({
        scopes: [scope],
      });
    } else {
      console.error(error);
      throw error;
    }
  }
};
