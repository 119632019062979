import React from 'react';
import { Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const SuccessModal = ({ visible, onClose, title }) => {
  return (
    <Modal
      visible={visible}
      centered
      footer={null}
      closable={false}
      onCancel={onClose}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '30px',
      }}
      width='30%'
    >
      <CheckCircleFilled style={{ fontSize: '4rem', color: '#52C41A' }} />
      <p style={{ fontSize: '1.2rem', marginTop: '40px', fontWeight: '600', textAlign: 'center' }}>
        {title}
      </p>
    </Modal>
  );
};

export default SuccessModal;
