import axios from 'axios';

import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getExistingAssetTrees(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/tree/getExistingAssetTrees`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newAssetTreeItem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/tree/newAssetTreeItem`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateAssetTreeItem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/tree/updateAssetTreeItem`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getOrgs(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/organization/getOrganizations`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getDepartments(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/department/getDepartments`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getSystems(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/system/getSystems`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getManufacturers(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/manufacturer/getManufacturers`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}
