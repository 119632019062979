import { Modal, Button, Input, Spin } from 'antd';
import { EditOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import AssetSelectionModal from '../AssetModal';
import Markdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

const KnowledgeResultModal = ({
  visible,
  onClose,
  onRegenerate,
  result,
  setResult,
  initialInput,
  pageIndex,
  onPageChange,
  loadingGenerate,
  generatorId,
  totalPages,
  inputsUsed,
}) => {
  const [inputValue, setInputValue] = useState(initialInput || '');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editableContent, setEditableContent] = useState(result?.content);
  const [isAssetModalVisible, setIsAssetModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (result && result.length > 0 && result[pageIndex - 1] && result[pageIndex - 1].content) {
      setEditableContent(result[pageIndex - 1].content);
      setInputValue(inputsUsed[pageIndex - 1] || '');
    }
  }, [pageIndex, result, inputsUsed]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (result && result.length > 0 && result[pageIndex - 1] && result[pageIndex - 1].content) {
      setEditableContent(result[pageIndex - 1]?.content);
    }
  };

  const handleSaveEdit = () => {
    setResult((current) => {
      current[pageIndex - 1].content = editableContent;
      return current;
    });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsEditing(false);
    }, 1000);
  };

  const handleOpenAssetModal = () => {
    setIsAssetModalVisible(true);
  };

  const handleCloseAssetModal = () => {
    onClose();
    setIsAssetModalVisible(false);
  };

  useEffect(() => {
    if (inputsUsed && inputsUsed.length > 0 && inputsUsed[pageIndex - 1]) {
      setInputValue(inputsUsed[pageIndex - 1]);
    } else {
      setInputValue(initialInput);
    }
  }, [inputsUsed, pageIndex, initialInput]);

  return (
    <>
      <Modal
        visible={visible}
        centered
        maskClosable={false}
        footer={[
          <Button
            key='add'
            type='primary'
            onClick={handleOpenAssetModal}
            style={{
              width: '30%',
              height: '40px',
              fontSize: '1.1rem',
              marginTop: isEditing ? '6rem' : '1rem',
              opacity: isEditing ? 0.5 : 1,
              pointerEvents: isEditing ? 'none' : 'auto',
            }}
          >
            {t('add_knowledge_button')}
          </Button>,
        ]}
        width='60%'
        onCancel={onClose}
        bodyStyle={{
          minHeight: '60vh',
          maxHeight: '68vh',
          padding: '20px',
        }}
      >
        <div style={{ opacity: isEditing ? 0.5 : 1, pointerEvents: isEditing ? 'none' : 'auto' }}>
          <h3>{t('new_request_input')}</h3>
          <Input.TextArea
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            placeholder={t('request_placeholder')}
            rows={3}
            style={{ marginBottom: '20px', fontSize: '1rem', maxHeight: '100px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              icon={<SyncOutlined />}
              onClick={() => onRegenerate(inputValue)}
              disabled={!inputValue}
              className='primary-button-hover'
              loading={loadingGenerate}
            >
              {t('generate_again')}
            </Button>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <h3 style={{ fontWeight: 'bold', margin: 0 }}>{t('virtual_engineer_search')}</h3>
          <div
            style={{
              fontSize: '1.1rem',
              opacity: isEditing ? 0.5 : 1,
              pointerEvents: isEditing ? 'none' : 'auto',
            }}
          >
            <span
              onClick={() => pageIndex > 1 && onPageChange(pageIndex - 1)}
              style={{ cursor: 'pointer', marginRight: '10px' }}
            >
              {'<'}
            </span>
            {pageIndex} / {totalPages}
            <span
              onClick={() => pageIndex < totalPages && onPageChange(pageIndex + 1)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            >
              {'>'}
            </span>
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            padding: !isEditing && '16px',
            border: '1px solid #bababa',
            borderRadius: '8px',
            backgroundColor: !isEditing ? '#f3f3f3' : '#FFFFFF',
            marginTop: '16px',
          }}
        >
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
            </div>
          )}
          <div style={{ opacity: loading ? 0.5 : 1 }}>
            {isEditing ? (
              <Input.TextArea
                value={editableContent}
                onChange={(e) => {
                  setEditableContent(e.target.value);
                }}
                rows={8}
                style={{
                  width: '100%',
                  fontSize: '1rem',
                  borderRadius: '8px',
                  padding: '10px',
                  boxSizing: 'border-box',
                  maxHeight: '240px',
                  overflowY: 'auto',
                }}
              />
            ) : (
              <div style={{ overflowY: 'auto', maxHeight: '150px', fontSize: '1rem' }}>
                <Markdown>{editableContent}</Markdown>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          {isEditing ? (
            <>
              <Button onClick={handleCancelEdit} className='button-hover'>
                {t('cancel-btn')}
              </Button>
              <Button
                type='primary'
                onClick={handleSaveEdit}
                style={{
                  backgroundColor: '#1890ff',
                  color: 'white',
                  marginLeft: '8px',
                }}
              >
                {t('save_btn')}
              </Button>
            </>
          ) : (
            <Button icon={<EditOutlined />} onClick={handleEditClick} className='button-hover'>
              {t('edit_btn')}
            </Button>
          )}
        </div>
      </Modal>
      <AssetSelectionModal
        closeEditModalVisible={onClose}
        visible={isAssetModalVisible}
        onClose={handleCloseAssetModal}
        editableContent={editableContent}
        generatorId={generatorId}
      />
    </>
  );
};

export default KnowledgeResultModal;
