import info from '../../images/info.svg';
import './style.css';

const Tooltip = ({ position = 'left', text = 'Tooltip text' }) => {
  return (
    <div className='toolTipContainer'>
      <div className='toolTipIcon'>
        <img src={info} />
      </div>
      <div
        className='toolTipTextContainer'
        style={{
          [position]: '2rem',
          alignSelf: 'center',
        }}
      >
        <div className='toolTipText'>{text}</div>
      </div>
    </div>
  );
};

export default Tooltip;
