import React from 'react';

import interconnections from '../../../../../images/interconnections.svg';

const InterconnectionsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={interconnections} alt={altText} style={{ width, height }} />;
};

export default InterconnectionsIconComponent;
