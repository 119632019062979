import AddKnowledge from './components/AddKnowledge';
import Favorite from './components/Favorite';
import NewChatModal from './components/NewChatModal';

import './style.css';

const VirtualEngineerButtons = () => {
  return (
    <div className='virtualEngineerButtons'>
      <Favorite />
      <AddKnowledge />
      <NewChatModal />
    </div>
  );
};

export default VirtualEngineerButtons;
