import Assets from './pages/plants/Assets';
import { MaintenanceHome } from './pages/Home/Home';
import Interconnections from './pages/plants/Interconnections';
import PlantsOutlet from './pages/plants/Outlet';
import PlantsGrid from './pages/plants/Plants';
import Systems from './pages/plants/Systems';
import WorkOrders from './pages/plants/WorkOrders';
import Instruments from './pages/Instruments';
import AllAssets from './pages/AllAssets';
import Details from './pages/plants/Details';
import { Navigate } from 'react-router-dom';

export default [
  {
    index: true,
    element: <Navigate to='/maintenance/plants' replace />,
  },
  // { path: '', element: <MaintenanceHome /> },
  {
    path: 'plants',
    element: <PlantsOutlet />,
    children: [
      { path: '', element: <PlantsGrid /> },
      { path: ':plantId?', element: <Assets /> },
      { path: ':plantId?/assets', element: <Assets /> },
      { path: ':plantId?/assets/:assetId?', element: <Details /> },
      { path: ':plantId?/interconnections', element: <Interconnections /> },
      { path: ':plantId?/systems', element: <Systems /> },
      { path: ':plantId?/work-orders', element: <WorkOrders /> },
    ],
  },
  {
    path: 'assets',
    element: <AllAssets />,
  },
  {
    path: 'instruments',
    element: <Instruments />,
  },
];
