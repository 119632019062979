import { useNavigate } from 'react-router-dom';
import Grid from '../../../../Template/layoutComponents/Grid';
import { useTranslation } from 'react-i18next';
import useMaintenanceState from '../../../atoms/variables';

import './style.css';

const PlantsGrid = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { existingAssetTrees, setChosenPlant } = useMaintenanceState();

  const handleClickNew = () => {
    setChosenPlant({
      id: 'newPlant',
      name: 'Nova Planta',
      icon: 'factory',
      category: null,
      type: 'plant',
      properties: [
        {
          label: t('name'),
          type: 'text',
          value: 'Nova Planta',
          main: true,
          required: true,
        },
        {
          label: 'Categoria',
          type: 'singleSelect',
          value: null,
          options: [],
          main: true,
          required: false,
        },
        {
          label: 'Organização',
          type: 'singleSelect',
          value: null,
          options: [],
          main: true,
          required: false,
        },
        {
          label: 'Departamento',
          type: 'singleSelect',
          value: null,
          options: [],
          main: true,
          required: false,
        },
      ],
      children: [],
    });
    navigate(`/maintenance/plants/newPlant/assets`);
  };
  const handleClickItem = (item) => {
    setChosenPlant(item);
    navigate(`/maintenance/plants/${item.id}/assets`);
  };

  return (
    <Grid items={existingAssetTrees} onClickItem={handleClickItem} onClickNew={handleClickNew} />
  );
};

export default PlantsGrid;
