import React from 'react';

import userGroups from '../../../../../images/userGroups.svg';

const UserGroupsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={userGroups} alt={altText} style={{ width, height }} />;
};

export default UserGroupsIconComponent;
