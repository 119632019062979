import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Typography, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useVirtualEngineerState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { getEquipments } from '../../helpers/equipment';

import useGlobalState from '../../../Template/atoms/variables';

const { Text } = Typography;

const CategoryDropdown = () => {
  const {
    equipmentCategories,
    currentCategory,
    setCurrentCategory,
    setEquipments,
    setCurrentEquipmentId,
    setCurrentThreadId,
    currentLocationId,
    currentEquipment,
  } = useVirtualEngineerState();

  const navigate = useNavigate();
  const { setPendingApiCalls } = useGlobalState();

  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { instance } = useMsal(0);

  const handleCategoryClick = ({ key }) => {
    const category = equipmentCategories.find((cat) => cat.id === key);
    setCurrentCategory(category);
    setCurrentThreadId(null);
    navigate('/virtual-engineer');
    setCurrentEquipmentId(null);
    if (currentLocationId) {
      triggerApiCall(currentLocationId, category.id);
    }
  };

  const triggerApiCall = async (locationId, categoryId) => {
    try {
      setPendingApiCalls((current) => current.concat(['getEquipments']));
      setCurrentEquipmentId(null);
      const response = await getEquipments(instance, locationId, categoryId);
      setEquipments(response?.length > 0 ? response : []);

      if (response.length == 0) {
        notificationApi.info({
          message: t('empty_database'),
          description: t('missing_items_description'),
          duration: 5,
        });
      }
    } catch (err) {
      console.log(err);
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item != 'getEquipments'));
    }
  };

  const categoryMenu = (
    <Menu onClick={handleCategoryClick}>
      {equipmentCategories?.map((category) => (
        <Menu.Item key={category.id}>{category.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {contextHolder}
      <Dropdown overlay={categoryMenu} trigger={['click']} defaultValue={'Selecione'}>
        <div
          style={{
            display: 'flex',
            flexShrink: 0,
            flexDirection: 'column',
            cursor: 'pointer',
            borderBottom: '2px solid #efefef',
            paddingBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
            <Text style={{ fontSize: '14px', color: '#bfbfbf' }}>{t('equipment_category')}</Text>
            <DownOutlined style={{ color: '#bfbfbf', marginLeft: '8px' }} />
          </div>

          <div style={{ marginTop: '.5rem' }}>
            <Text>{currentCategory?.label ?? t('select_category')}</Text>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default CategoryDropdown;
