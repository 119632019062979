import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMaintenanceState from '../../../atoms/variables';
import Tabs from '../../../../Template/layoutComponents/Tabs';
import DocumentTabContent from './components/documentTabContent';

import './style.css';
import useTemplateState from '../../../../Template/atoms/variables';

const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

const Details = () => {
  const { existingAssetTrees, chosenPlant } = useMaintenanceState();
  const navigate = useNavigate();
  const { plantId, assetId } = useParams();

  const { companyInfo } = useTemplateState();

  const [chosenLevel, setChosenLevel] = useState(null);

  useEffect(() => {
    if (!chosenPlant || !plantId || !assetId) {
      navigate(`/maintenance/plants/${plantId}/assets`);
    } else {
      setChosenLevel(findById(chosenPlant, assetId));
    }
  }, []);

  function findById(tree, targetId) {
    if (tree.id === targetId) {
      return tree;
    }

    for (let child of tree.children) {
      const result = findById(child, targetId);
      if (result) {
        return result;
      }
    }

    return null;
  }

  function findItemType(id) {
    function checkIfIsItem(id, item) {
      if (item.id === id) return item;
      else {
        for (let child of item.children) {
          let item = checkIfIsItem(id, child);
          if (item) return item;
        }
      }
    }
    if (existingAssetTrees) {
      let tree = existingAssetTrees.find((tree) => tree.id === plantId);
      if (tree) {
        const item = checkIfIsItem(id, tree);
        return item ? item.type : null;
      }
    }
  }

  return (
    <div id='assetDetails'>
      <Tabs
        tabs={[
          { label: 'Geral', content: <div></div>, disabled: true },
          {
            label: 'Commissionamento',
            content: <div></div>,
            disabled: !companyInfo?.commissioning,
          },
          { label: 'Ordens de Serviço', content: <div></div>, disabled: true },
          {
            label: 'Documentos',
            content: <DocumentTabContent plantId={plantId} assetId={assetId} />,
            disabled: false,
          },
          assetTypes.includes(findItemType(assetId)) && {
            label: 'Chat',
            content: <div></div>,
            disabled: !companyInfo.modules?.includes('/virtual-engineer'),
            onClick: () => navigate(`/virtual-engineer/equipments/${assetId}/threads/new`),
          },
        ].filter((tab) => tab)}
        defaultOpen={3}
      />
    </div>
  );
};

export default Details;
