import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const socketIdState = atom({
  key: 'socketId',
  default: null,
});

export const currentThreadId = atom({
  key: 'currentThreadId',
  default: null,
});

export const currentEquipmentId = atom({
  key: 'currentEquipmentId',
  default: null,
});

export const currentCategory = atom({
  key: 'currentCategory',
  default: null,
});

export const currentLocationId = atom({
  key: 'currentLocation',
  default: null,
});

export const currentEquipment = atom({
  key: 'currentEquipment',
  default: null,
});

export const conversations = atom({
  key: 'conversations',
  default: [],
});

export const equipments = atom({
  key: 'equipments',
  default: [],
});

export const equipmentCategories = atom({
  key: 'equipmentCategories',
  default: null,
});

export const userPreferences = atom({
  key: 'userPreferences',
  default: {}
})

export const locations = atom({
  key: 'locations',
  default: [],
});

export const favorites = atom({
  key: 'favorites',
  default: []
})

export const tablePage = atom({
  key: 'tablePage',
  default: 1
})

export const pageCount = atom({
  key: 'pageCount',
  default: 1
})

export const equipmentFilter = atom({
  key: 'equipmentFilter',
  default: []
})
export const manufacturers = atom({
  key: 'manufacturers',
  default: [],
});

export default function useVirtualEngineerState() {
  return {
    socketIdState: useRecoilValue(socketIdState),
    setSocketIdState: useSetRecoilState(socketIdState),
    currentThreadId: useRecoilValue(currentThreadId),
    setCurrentThreadId: useSetRecoilState(currentThreadId),
    currentEquipmentId: useRecoilValue(currentEquipmentId),
    setCurrentEquipmentId: useSetRecoilState(currentEquipmentId),
    currentEquipment: useRecoilValue(currentEquipment),
    setCurrentEquipment: useSetRecoilState(currentEquipment),
    currentCategory: useRecoilValue(currentCategory),
    setCurrentCategory: useSetRecoilState(currentCategory),
    currentLocationId: useRecoilValue(currentLocationId),
    setCurrentLocationId: useSetRecoilState(currentLocationId),
    conversations: useRecoilValue(conversations),
    setConversations: useSetRecoilState(conversations),
    equipments: useRecoilValue(equipments),
    setEquipments: useSetRecoilState(equipments),
    equipmentCategories: useRecoilValue(equipmentCategories),
    setEquipmentCategories: useSetRecoilState(equipmentCategories),
    locations: useRecoilValue(locations),
    setLocations: useSetRecoilState(locations),
    userPreferences: useRecoilValue(userPreferences),
    setUserPreferences: useSetRecoilState(userPreferences),
    favorites: useRecoilValue(favorites),
    setFavorites: useSetRecoilState(favorites),
    tablePage: useRecoilValue(tablePage),
    setTablePage: useSetRecoilState(tablePage),
    pageCount: useRecoilValue(pageCount),
    setPageCount: useSetRecoilState(pageCount),
    manufacturers: useRecoilValue(manufacturers),
    setManufacturers: useSetRecoilState(manufacturers),
  };
}
