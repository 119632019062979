import React from 'react';
import { Navigate } from 'react-router-dom';

import SettingsHome from './pages/Home';
import AssetTreeBuild from './pages/asset-tree/AssetTreeBuild';
import AssetTreeManage from './pages/asset-tree/AssetTreeManage';
import AssetCategories from './pages/asset-tree/AssetCategories';
import AssetTreeOutlet from './pages/asset-tree/Outlet';

export default [
  { path: '', element: <Navigate to='asset-tree' replace /> },
  { path: 'organizations', element: <SettingsHome /> },
  { path: 'departments', element: <SettingsHome /> },
  { path: 'cost-centers', element: <SettingsHome /> },
  {
    path: 'asset-tree',
    element: <AssetTreeOutlet />,
    children: [
      { path: '', element: <AssetTreeBuild /> },
      { path: 'build', element: <AssetTreeBuild /> },
      { path: 'manage', element: <AssetTreeManage /> },
      { path: 'manage/:chosenType', element: <AssetCategories /> },
    ],
  },
  { path: 'user-groups', element: <SettingsHome /> },
  { path: 'users', element: <SettingsHome /> },
];
