import { Button, notification, Spin, ConfigProvider } from 'antd';

import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

import { saveAssetFile } from '../../../../helpers/files';
import useTemplateGlobatState from '../../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';
import Dragger from 'antd/es/upload/Dragger';
import { useEffect, useState } from 'react';

const AddFilesModal = ({ plantId, assetId, reloadFileList }) => {
  const { setModalOpen, setModalContent, setModalTitle, setModalConfirm, setModalDisabled } =
    useTemplateGlobatState();

  const { instance } = useMsal();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();

  const [totalProgress, setTotalProgress] = useState(0); // Progresso total do upload de todos os arquivos
  const [progressPerFile, setProgressPerFile] = useState([]); // Progresso individual de cada arquivo

  const modalContent = (
    <div className='equipmentFiles'>
      <Dragger
        onRemove={async (file) => {}}
        customRequest={async ({ file, onSuccess, onError }) => {
          onSuccess(null, file);
          setModalDisabled(false);
        }}
        accept='application/pdf'
        multiple
        onChange={(info) => {
          if (info.fileList.length == 0) setModalDisabled(true);
          setUploadedFiles(info.fileList);
        }}
        onDrop={(e) => console.log('Dropped files', e.dataTransfer.files)}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Clique ou arraste para fazer upload de um arquivo</p>
        <p className='ant-upload-hint'>Apenas arquivos .pdf</p>
        <p className='ant-upload-hint'>Suporte para upload único ou em massa.</p>
      </Dragger>
    </div>
  );

  const loadingContent = (
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            dotSizeLG: 80,
          },
        },
      }}
    >
      <Spin spinning={totalProgress > 0} percent={totalProgress} size='large' />
      <p className='loading-text'>Aguarde...</p>
    </ConfigProvider>
  );

  useEffect(() => {
    setModalContent(loadingContent);
  }, [totalProgress]);

  useEffect(() => {
    setModalConfirm(() => async () => {
      await handleUploadFiles();
    });
  }, [uploadedFiles]);

  useEffect(() => {
    updateOverallProgress();
  }, [progressPerFile]);

  // Função para atualizar a barra de progresso com o progresso total
  function updateOverallProgress() {
    const overallProgress =
      progressPerFile.reduce((sum, progress) => sum + progress, 0) / uploadedFiles.length;
    setTotalProgress(overallProgress);
  }

  const onUploadProgress = (progressEvent, index) => {
    const { loaded, total } = progressEvent;
    // Calcula o progresso do arquivo
    setProgressPerFile((current) => {
      let tmp = [...current];
      tmp[index] = Math.floor((loaded * 100) / total);
      return tmp;
    });
  };

  const handleUploadFiles = async () => {
    setProgressPerFile(Array(uploadedFiles.length).fill(0));
    setTotalProgress(0);
    const uploadPromises = uploadedFiles.map((file, index) =>
      saveAssetFile(instance, assetId, file.originFileObj, (progressEvent) =>
        onUploadProgress(progressEvent, index),
      ),
    );
    await Promise.all(uploadPromises);
    setTotalProgress(100);
    setModalDisabled(false);
    setModalOpen(false);
    setTotalProgress(0);
    setModalContent(null);
    reloadFileList();
  };

  return (
    <Button
      className='createFileButton'
      size='large'
      icon={<UploadOutlined />}
      style={{
        height: '3rem',
        background: '#1777FF',
        color: 'white',
      }}
      onClick={() => {
        setModalTitle('Adicione documentos');
        setModalDisabled(true);
        setModalConfirm(() => async () => {
          await handleUploadFiles();
        });
        setModalContent(modalContent);
        setModalOpen(true);
      }}
    >
      Adicionar Documento
    </Button>
  );
};

export default AddFilesModal;
