import React from 'react';

import allAssets from '../../../../../images/allAssets.svg';

const AllAssetsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={allAssets} alt={altText} style={{ width, height }} />;
};

export default AllAssetsIconComponent;
