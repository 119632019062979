import Checkbox from '../Checkmark';
import Date from '../Date';
import Input from '../Input';
import Select from '../SingleSelect';
import MultiSelect from '../MultiSelect';
import { useTranslation } from 'react-i18next';
import './style.css';

const Forms = ({
  id,
  inputs,
  onInputBlur,
  onInputChange,
  categories,
  departments,
  orgs,
  systems,
  manufacturers,
}) => {
  const { t } = useTranslation();

  const labelMapping = {
    Categoria: 'category',
    Departamento: 'department',
    Organização: 'organization',
    Sistema: 'system',
    Fabricante: 'manufacturer',
    Nome: 'name',
    'Fora de serviço': 'out_of_service',
    'Data de instalação': 'installation_date',
  };

  const concatDynamicOptions = (input) => {
    if (input.main) {
      const mappedKey = labelMapping[input.label];
      switch (mappedKey) {
        case 'category':
          return categories;
        case 'department':
          return departments;
        case 'organization':
          return orgs;
        case 'system':
          return systems;
        case 'manufacturer':
          return manufacturers;
        default:
          return false;
      }
    }
    return false;
  };

  const handleInputType = (input, index) => {
    switch (input?.type) {
      case 'text':
        return (
          <Input
            maxLength={input?.maxLength}
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            placeholder={input.value}
            type={'text'}
            onBlur={onInputBlur}
            value={input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'number':
        return (
          <Input
            maxLength={input?.maxLength}
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            placeholder={input.value}
            type={'number'}
            value={input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'singleSelect':
        return (
          <Select
            key={`${id}-input-${index}-${input?.type}-${input.label}-${JSON.stringify(input.value)}`}
            value={input.value}
            options={(concatDynamicOptions(input) || []).concat(input.options)}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'multiSelect':
        return (
          <MultiSelect
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            multi={true}
            value={input.value}
            options={(concatDynamicOptions(input) || []).concat(input.options)}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'bool':
        return (
          <Checkbox
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            checked={input.value}
            label=''
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'date':
        return (
          <Date
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            value={input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
    }
  };

  return (
    <div className='formsBody'>
      {inputs?.map((input, index) => {
        return (
          <div key={`${input.label}-${index}`} className='formsInputContainer'>
            <div className='formsInputLabel'>{t(labelMapping[input.label] || input.label)}:</div>
            <div className='formsInput'>{handleInputType(input, index)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Forms;
