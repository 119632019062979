import React from 'react';

import organizations from '../../../../../images/organizations.svg';

const OrganizationsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={organizations} alt={altText} style={{ width, height }} />;
};

export default OrganizationsIconComponent;
