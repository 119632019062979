import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import SearchButton from '../../../../../../Template/layoutComponents/SearchButton';

const SearchBarWithButton = ({
  onSearch,
  beforeSearch,
  setSearchValue,
  setSearchIsTrue,
  placeholder,
}) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  setSearchValue(searchText);

  const handleSearch = async () => {
    const result = await beforeSearch(1, 100);
    const { equipments = [], totalPages = 0, totalEquipments = 0 } = result || {};
    const value = searchText.trim().toLowerCase();

    let filteredData = equipments.filter((item) => item.name?.toLowerCase().includes(value));
    setSearchIsTrue(true);

    onSearch(filteredData);
    setIsSearchApplied(true);
  };

  const handleReset = async () => {
    setSearchText('');
    setSearchValue('');
    setSearchIsTrue(false);
    setIsSearchApplied(false);
    onSearch([]);
    await beforeSearch(1, 10);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px' }}>
      <Input
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{
          width: '30%',
          padding: '5px 8px',
          borderRadius: '6px',
        }}
      />
      {!isSearchApplied ? (
        <SearchButton
          onClick={handleSearch}
          disabled={!searchText.trim()}
          style={{ marginLeft: '10px', height: '2rem', width: '3.5rem' }}
        />
      ) : (
        <Button
          onClick={handleReset}
          icon={<CloseOutlined />}
          style={{ marginLeft: '10px', height: '2rem', width: '3.5rem' }}
        ></Button>
      )}
    </div>
  );
};

export default SearchBarWithButton;
