import React from 'react';

import helmet from '../../../../../images/helmet.svg';
import helmetSelected from '../../../../../images/helmetSelected.svg';

const MaintenanceIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={selected ? helmetSelected : helmet} alt={altText} style={{ width, height }} />;
};

export default MaintenanceIconComponent;
