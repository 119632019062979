import React from 'react';

import arrowUp from '../../../../../images/arrowUp.svg';
import arrowDown from '../../../../../images/arrowDown.svg';

const ArrowIconComponent = ({
  open = false,
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return (
    <img
      src={open ? arrowUp : arrowDown}
      alt={altText}
      style={{ width, height, position: 'absolute', right: '1rem' }}
    />
  );
};

export default ArrowIconComponent;
