import { FileExclamationOutlined, DeleteOutlined } from '@ant-design/icons';
import useVirtualEngineerState from '../../../VirtualEnginner/atoms/variables';

import { useMsal } from '@azure/msal-react';

import { notification } from 'antd';
import { deleteAssetFile } from '../../helpers/files';
import AddFilesModal from './components/AddFilesModal';

import { useState } from 'react';
import useGlobalState from '../../../Template/atoms/variables';

import './style.css';

const FileManager = ({ files }) => {
  const { currentEquipment } = useVirtualEngineerState();
  const { setPendingApiCalls } = useGlobalState();
  const { instance } = useMsal();

  const [notificationApi, contextHolder] = notification.useNotification();

  const [currentFiles, setCurrentFiles] = useState(files);

  const triggerApiCall = async (fileName) => {
    try {
      setPendingApiCalls((current) => current.concat(['deleteFile']));
      await deleteAssetFile(instance, currentEquipment?._id, fileName);
      setCurrentFiles((current) => current.filter((file) => !file.name.includes(fileName)));
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: 'Falha ao deletar documentos',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'deleteFile'));
    }
  };

  return currentFiles.length > 0 ? (
    <div className='equipmentcurrentFiles'>
      {contextHolder}
      <div className='equipmentFileTable'>
        <div className='equipmentFileRow title'>
          <div className='equipmentFileCell'>Nome</div>
          <div className='equipmentFileCell'>Arquivo</div>
          <div className='equipmentFileCell'>Deletar</div>
        </div>
        {currentFiles.map((file) => {
          return (
            <div className='equipmentFileRow'>
              <div className='equipmentFileCell'>{file.name.split(/\//g)[2]}</div>
              <div className='equipmentFileCell'>{<a href={file.url}>Link</a>}</div>
              <div
                className='equipmentFileCell'
                style={{
                  fontSize: '1.2rem',
                  color: 'red',
                  justifyContent: 'end',
                }}
                onClick={() => {
                  //delete
                  triggerApiCall(file.name.split(/\//g)[2]);
                }}
              >
                <DeleteOutlined style={{ cursor: 'pointer' }} />
              </div>
            </div>
          );
        })}
      </div>
      <AddFilesModal notificationApi={notificationApi} />
    </div>
  ) : (
    <div className='noEquipmentFiles'>
      {contextHolder}
      <FileExclamationOutlined style={{ fontSize: '2rem', flexShrink: 0 }} />
      <div className='noEquipmentFilesText'>
        Parece que não existem documentos associados a este equipamento!
      </div>
      <AddFilesModal notificationApi={notificationApi} />
    </div>
  );
};

export default FileManager;
