import { createConversation } from './chat';

export const startConversation = async (
  equipmentId,
  instance,
  notificationApi,
  navigate,
  setPendingApiCalls,
  setConversations,
  setCurrentCategory,
  setCurrentEquipment,
  setCurrentThreadId,
) => {
  let createConversationResponse;
  try {
    setPendingApiCalls((current) => current.concat(['createConversation']));
    createConversationResponse = await createConversation(instance, equipmentId);
    setConversations((current) => {
      const updatedConversations = [createConversationResponse, ...current].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );

      return updatedConversations;
    });

    if (createConversationResponse.subject) {
      setCurrentEquipment(createConversationResponse.subject);
    }
    setCurrentThreadId(createConversationResponse._id);
    navigate(
      `/virtual-engineer/equipments/${equipmentId}/threads/${createConversationResponse._id}`,
      {
        replace: true,
      },
    );
  } catch (err) {
    console.error(err);
    notificationApi.error({
      message: 'Falha ao criar nova conversa',
      description: err?.response?.data?.message
        ? `${err?.response?.data?.message} (${err?.response?.status})`
        : err?.message,
      duration: 5,
    });

    if (createConversationResponse)
      setConversations((current) =>
        current.filter((conversation) => conversation._id != createConversationResponse._id),
      );

    navigate(`/virtual-engineer`);
  } finally {
    setPendingApiCalls((current) => current.filter((item) => item !== 'createConversation'));
  }
};
