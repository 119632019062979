import React from 'react';

import globe from '../../../../../images/globe.svg';
import globeSelected from '../../../../../images/globeSelected.svg';

const MaintenanceIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={selected ? globeSelected : globe} alt={altText} style={{ width, height }} />;
};

export default MaintenanceIconComponent;
